import { ActiveContainer } from "../../active-container";
import { ReloadIcon } from "../../reload-icon";
import { SimpleSwiper } from "../../simple-swiper";
import { useContext, useEffect, useRef, useState } from "react";
import { CaretRight } from "./caret-right";
import { Speech } from "./speech";
import { Agenda as AgendaType, Speech as SpeechType } from "../../../types";
import { AgendasContext } from "../context/agendas.context";

export const AgendaItem = ({
  agenda,
  activeContainer,
  liveContainer,
  firstItem,
  lastItem,
  onlyItem,
  greenListed,
  deposed,
  containerDisabled,
  agendaActionsDisabled,
  parentRef,
  onAgendaClick,
  onSpeechClick,
}: {
  agenda: AgendaType;
  activeContainer: boolean;
  liveContainer: boolean;
  firstItem?: boolean;
  lastItem: boolean;
  onlyItem: boolean;
  greenListed: boolean;
  deposed: boolean;
  containerDisabled: boolean;
  agendaActionsDisabled: boolean;
  parentRef: HTMLDivElement | null | undefined;
  onAgendaClick: (agenda?: AgendaType) => void;
  onSpeechClick: (
    agenda: AgendaType,
    speech: SpeechType,
    agendaRef: HTMLDivElement | null
  ) => void;
}) => {
  const {
    agendas,
    activeAgenda,
    activeAssociatedAgenda,
    liveAgenda,
    liveAssociatedAgenda,
    viewingLive,
    recalledAgenda,
    activeSpeech,
    scrollIntoViewID,
    activeAgendaRef,
    liveAgendaRef,
    onRef,
    onScrollFinish,
  } = useContext(AgendasContext);
  const [isActiveAgenda, setIsActiveAgenda] = useState(false);
  const [isLiveAgenda, setIsLiveAgenda] = useState(false);
  const [speechHeights, setSpeechHeights] = useState<number[]>([]);
  const [minSpeechTitleHeight, setMinSpeechTitleHeight] = useState(0);
  const [agendaActive, setAgendaActive] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disableSpeeches, setDisableSpeeches] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDisabled(false);
    setDisableSpeeches(false);

    if (containerDisabled || agendaActionsDisabled) {
      setDisabled(true);
      return;
    }

    if (deposed || agenda.field_deposed) {
      setDisabled(true);
      setDisableSpeeches(true);
      return;
    }

    if (activeAssociatedAgenda) {
      setDisabled(agenda.id === activeAssociatedAgenda?.id && !activeSpeech);
      return;
    }
    setDisabled(agenda.id === activeAgenda?.id && !activeSpeech);
  }, [
    agenda,
    deposed,
    containerDisabled,
    agendaActionsDisabled,
    activeAgenda,
    activeAssociatedAgenda,
    activeSpeech,
    liveAgenda,
    liveAssociatedAgenda,
  ]);

  useEffect(() => {
    if (activeAssociatedAgenda) {
      setIsActiveAgenda(
        activeContainer && agenda.id === activeAssociatedAgenda.id
      );
      return;
    }
    setIsActiveAgenda(activeContainer && agenda.id === activeAgenda?.id);
  }, [agenda, activeAgenda, activeAssociatedAgenda, activeContainer]);

  useEffect(() => {
    setIsLiveAgenda(
      liveContainer &&
        (agenda.id === liveAssociatedAgenda?.id ||
          (!liveAssociatedAgenda && agenda.id === liveAgenda?.id))
    );
  }, [agenda, liveAgenda, liveAssociatedAgenda, liveContainer]);

  useEffect(() => {
    if (isActiveAgenda || isLiveAgenda) {
      onRef(
        isLiveAgenda,
        activeSpeech
          ? (containerRef.current as HTMLDivElement)
          : (parentRef as HTMLDivElement),
        isLiveAgenda && isActiveAgenda
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveAgenda, isLiveAgenda]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (
        agenda.id !== scrollIntoViewID ||
        (activeAgenda?.id === scrollIntoViewID && !isActiveAgenda) ||
        (liveAgenda?.id === scrollIntoViewID && !isLiveAgenda)
      ) {
        return;
      }
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
      onScrollFinish();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scrollIntoViewID,
    isActiveAgenda,
    isLiveAgenda,
    liveAgendaRef,
    activeAgendaRef,
  ]);

  useEffect(() => {
    if (speechHeights.length) {
      setMinSpeechTitleHeight(Math.max(...speechHeights));
    }
  }, [speechHeights]);

  const getAgendaNumber = () => {
    const processNum = agenda.field_consulting_process?.uri.split("=")[1];
    if (processNum) {
      const [first, second] = processNum.split("-");
      return `${removeZeroPadding(first)}/${removeZeroPadding(second)}`;
    }
    return "";
  };

  const removeZeroPadding = (input: string) => {
    const regex = /^0+(?!$)/;
    return input.replace(regex, "");
  };

  const handleSpeechInit = (height: number) => {
    const newHeights = speechHeights;
    newHeights.push(height);
    setSpeechHeights(newHeights);
  };

  const getAgendaColor = () => {
    const isDeposed = deposed || agenda.field_deposed;
    if (liveContainer) {
      return isDeposed ? "text-white/50" : "text-white";
    }

    return isDeposed ? "text-black/50" : "text-black";
  };

  return (
    <ActiveContainer
      onChange={(active) => setAgendaActive(active && !disabled)}
      className={`${
        liveContainer
          ? agendaActive || activeContainer || viewingLive
            ? "!bg-red-900"
            : "!bg-red-400"
          : ""
      }`}
      ref={containerRef}
    >
      {firstItem && agenda.field_green_list && (
        <h2
          className={`text-xs ${
            liveContainer ? "text-white" : "text-green-800"
          } font-bold px-4 pt-3 pb-1`}
        >
          Zusammengefasste Beratungsgegenstände
        </h2>
      )}
      <div
        className={`relative flex w-full px-3 py-2 outline-none ${
          !disabled ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={() => {
          if (!disabled) {
            onAgendaClick();
          }
        }}
        onKeyDown={(evt) => {
          if (evt.key === " ") {
            evt.preventDefault();
          }
        }}
        onKeyUp={(evt) => {
          if (!disabled && ["Enter", " "].includes(evt.key)) {
            onAgendaClick();
          }
        }}
        role="button"
        aria-disabled={disabled}
        tabIndex={!disabled ? 0 : undefined}
      >
        <div
          className={`flex flex-col min-w-[16px] md:min-w-[80px] shrink-0 md:pl-1.5 -mb-6 md:-mb-2 ${
            lastItem ? "!mb-0" : ""
          } overflow-hidden`}
        >
          <div
            className={`hidden md:flex flex-col w-max items-end font-semibold ${
              liveContainer ? "text-white" : "text-black"
            }`}
          >
            <span
              className={`font-bold ${
                deposed || agenda.field_deposed ? "line-through" : ""
              } ${getAgendaColor()}`}
            >
              TOP {agenda.field_item_number}
            </span>
            {(liveAssociatedAgenda?.id === agenda.id ||
              (!liveAssociatedAgenda && liveAgenda?.id === agenda.id)) && (
              <div className="flex items-center text-2xs text-left text-white font-thin">
                <div className="w-2.5 h-2.5 rounded-[50%] bg-white mr-1.5 mt-[1px]"></div>
                LIVE
              </div>
            )}
          </div>
          <div
            className={`min-h-[25px] w-1 md:w-[3px] grow mr-3 md:mr-0 md:ml-6 mt-1.5 ${
              liveContainer
                ? "bg-white"
                : greenListed
                ? "bg-green-500"
                : "bg-red-400"
            } ${lastItem ? "md:hidden" : ""} ${onlyItem ? "hidden" : ""}`}
          ></div>
        </div>
        <div
          className={`${liveContainer ? "text-white" : "text-black"} grow ${
            !lastItem ? "pb-7" : ""
          } overflow-hidden`}
        >
          <div>
            <span
              className={`md:hidden font-bold ${
                deposed || agenda.field_deposed ? "line-through" : ""
              } ${getAgendaColor()}`}
            >
              TOP {agenda.field_item_number}
            </span>
            {(deposed || agenda.field_deposed) && (
              <span
                className={`ml-4 md:ml-0 text-black/50 ${
                  liveContainer ? "!text-white/50" : ""
                }`}
              >
                ABGESETZT
              </span>
            )}
            {(agenda.field_renewed_call) && (
              <span
                className={`ml-4 md:ml-0 text-black/50 ${
                  liveContainer ? "!text-white/50" : ""
                }`}
              >
                WIEDERAUFRUF
              </span>
            )}
          </div>
          <h3
            className={`max-w-full overflow-hidden text-base font-normal text-ellipsis ${
              !deposed && !agenda.field_deposed && agendaActive && !disabled
                ? "underline"
                : ""
            } ${getAgendaColor()}`}
          >
            {agenda.title.substring(agenda.title.indexOf(": ") + 1)}
          </h3>
          <div className="flex gap-2">
            {agenda.field_consulting_process && (
              <a
                className={`flex w-max h-max text-2xs ${
                  liveContainer ? "!text-white" : "!text-red-400"
                } mt-2 hover:underline focus:underline focus:outline-none`}
                href={agenda.field_consulting_process.uri}
                target="_blank"
                rel="noreferrer"
                onClick={(evt) => evt.stopPropagation()}
              >
                <CaretRight className="mt-1 mr-1" />
                Drucksachen {getAgendaNumber()}
              </a>
            )}
            {agenda.field_see_also?.length > 0 && (
              <div className="flex flex-col ml-auto">
                {agenda.field_see_also.map((ag, i) => (
                  <button
                    key={`${agenda.id}_${ag.id}_${i}`}
                    className={`flex text-left text-2xs mr-6 ${
                      liveContainer ? "text-white/80" : "text-black/40"
                    } py-1 ${!i ? "mt-[4px]" : ""} ${
                      recalledAgenda?.field_deposed ||
                      activeAgenda?.id === recalledAgenda?.id ||
                      (liveAgenda && liveAgenda.id !== recalledAgenda?.id)
                        ? ""
                        : "hover:underline"
                    } focus:underline focus:outline-none`}
                    disabled={
                      recalledAgenda?.field_deposed ||
                      activeAgenda?.id === recalledAgenda?.id ||
                      (liveAgenda && liveAgenda.id !== recalledAgenda?.id)
                    }
                    onClick={(evt) => {
                      evt.stopPropagation();
                      onAgendaClick(recalledAgenda as AgendaType);
                    }}
                  >
                    <ReloadIcon
                      className="mr-1.5 mt-[3px]"
                      fillClass={liveContainer ? "fill-white" : "fill-black/40"}
                    />
                    Erneuter Aufruf nach TOP {agenda.field_recalled_after_item_no}
                  </button>
                ))}
              </div>
            )}
          </div>
          {!deposed &&
          !agenda.field_deposed &&
          !greenListed &&
          agenda.field_speeches?.length ? (
            <SimpleSwiper
              className="mt-3"
              buttonStrokeClass={liveContainer ? "stroke-white" : undefined}
            >
              {agenda.field_speeches.map((speech) => (
                <Speech
                  key={`${agenda.id}_${speech.id}`}
                  speech={speech}
                  minHeight={minSpeechTitleHeight}
                  live={liveContainer}
                  containerDisabled={containerDisabled || disableSpeeches}
                  onSpeechClick={(speech) =>
                    onSpeechClick(agenda, speech, containerRef.current)
                  }
                  onInit={handleSpeechInit}
                />
              ))}
            </SimpleSwiper>
          ) : undefined}
          {(liveAssociatedAgenda?.id === agenda.id ||
            (!liveAssociatedAgenda && liveAgenda?.id === agenda.id)) && (
            <div
              className={`flex md:hidden items-center text-2xs text-left text-white font-thin mt-3 -mb-4 ${
                lastItem ? "mb-0" : ""
              }`}
            >
              <div className="w-2.5 h-2.5 rounded-[50%] bg-white mr-1.5 mt-[1px]"></div>
              LIVE
            </div>
          )}
        </div>
      </div>
    </ActiveContainer>
  );
};
