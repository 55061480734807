import { MediaListAction } from "kaltura-typescript-client/api/types/MediaListAction";
import { CaptionAssetListAction } from "kaltura-typescript-client/api/types/CaptionAssetListAction";
import { KalturaMediaEntryFilter, KalturaMediaEntryFilterArgs } from "kaltura-typescript-client/api/types/KalturaMediaEntryFilter";
import { KalturaAssetFilter } from "kaltura-typescript-client/api/types/KalturaAssetFilter";
import { drupal } from "src/lib/drupal";
import { getParams } from "src/lib/get-params";
import { initKalturaSession, kaltura } from "src/lib/kaltura";
import { ApiParams, LivestreamResponse, Video } from "src/types";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import axios from "axios";

export async function getPlenaryMeetings(params: ApiParams = {}) {
  return await drupal.getResourceCollection<Video[]>("node--plenary_meeting", {
    params: getParams("node--plenary_meeting", params),
  });
}

export async function getOtherVideos(params: ApiParams = {}) {
  return await drupal.getResourceCollection<Video[]>("node--video_others", {
    params: getParams("node--video_others", params),
  });
}

export async function getKalturaMediaEntries(filterBy?: KalturaMediaEntryFilterArgs) {
  const filter = new KalturaMediaEntryFilter(filterBy);
  const pager = new KalturaFilterPager({ pageSize: 500 });
  await initKalturaSession();
  const data = [];
  while (true) {
    const response = await kaltura.request(new MediaListAction({ filter, pager }));
    if (!response) {
      break;
    }
    data.push(...response.objects);
    if (data.length >= response.totalCount) {
      break;
    }
  }
  return data;
}

export async function getKalturaMediaEntry(entryId: string) {
  const filter = new KalturaMediaEntryFilter({ idEqual: entryId});
  await initKalturaSession();
  const response = await kaltura.request(new MediaListAction({ filter }));
  return response?.objects[0];
}

export async function hasKalturaMediaEntrySubtitles(entryId: string) {
  const filter = new KalturaAssetFilter({ entryIdEqual: entryId})
  await initKalturaSession();
  const response = await kaltura.request(new CaptionAssetListAction({ filter }));
  return !!response?.totalCount;
}

export async function getLiveSpecialEvent() {
  const liveRes = await axios.get<LivestreamResponse>(
    `${process.env.REACT_APP_SYNC_API_BASE_URL}/live/drupalnode`
  );

  if (liveRes.status === 204) {
    return undefined;
  }

  const specialEvent = await drupal.getResource<Video>(
    "node--video_others", liveRes.data.value
  );

  return specialEvent;
}