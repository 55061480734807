import { DrupalClient } from './drupal-jsonapi/client'

export const drupal = new DrupalClient(
  process.env.REACT_APP_DRUPAL_BASE_URL as string,
  {
    auth: {
      clientId: process.env.REACT_APP_DRUPAL_CLIENT_ID as string,
      clientSecret: process.env.REACT_APP_DRUPAL_CLIENT_SECRET as string,
    },
  }
)