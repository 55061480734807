import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ActiveContainer } from "./active-container";
import { Image } from "./image";
import { PlayIcon } from "./play-icon";
import { thumbnailUrl } from "src/lib/thumbnail-url";

const NORMAL_MAX_LINES = 13;
const SMALL_MAX_LINES = 4;
const MIN_BODY_LINES = 2;
const DESCRIPTION_LINES = 2;

const ELLIPSED_TEXT = "whitespace-nowrap overflow-hidden text-ellipsis";

export function ItemPreview({
  title,
  imgSrc,
  imgAlt,
  to,
  overlayTo,
  description,
  hasDescription,
  body,
  bodyHidden,
  size = "normal",
  className = "",
}: {
  title: string;
  imgSrc: string | undefined;
  imgAlt: string | undefined;
  to?: string | undefined;
  overlayTo?: string | undefined;
  description?: string | undefined;
  hasDescription?: boolean;
  body?: string | undefined;
  bodyHidden?: boolean;
  size?: "normal" | "small";
  className?: string;
}) {
  const [linkActive, setLinkActive] = useState(false);
  const [textActive, setTextActive] = useState(false);
  const [titleLineClamp, setTitleLineClamp] = useState(0);
  const [bodyLineClamp, setBodyLineClamp] = useState(0);
  const [hideDescription, setHideDescription] = useState(false);
  const titleRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    let availableLines = size === "normal" ? NORMAL_MAX_LINES : SMALL_MAX_LINES;
    if (size === "normal" && (!body || (bodyHidden && !textActive))) {
      availableLines -= 8;
    }
    const titleLines = titleRef.current?.getClientRects().length as number;
    let titleClamp = Math.min(titleLines, availableLines);
    let bodyClamp = availableLines;
    if (!hideDescription) {
      titleClamp = Math.min(titleClamp, availableLines - DESCRIPTION_LINES);
      bodyClamp -= DESCRIPTION_LINES;
    }
    if (body?.trim()) {
      const remainingLines =
        availableLines -
        (!hideDescription ? DESCRIPTION_LINES : 0) -
        titleClamp;
      if (remainingLines < MIN_BODY_LINES) {
        titleClamp -= MIN_BODY_LINES - remainingLines;
      }
      bodyClamp -= titleClamp;
    }
    setTitleLineClamp(titleClamp);
    setBodyLineClamp(bodyClamp);
  }, [
    title,
    description,
    hasDescription,
    body,
    bodyHidden,
    textActive,
    hideDescription,
    size,
  ]);

  useEffect(() => {
    setHideDescription(
      (!description && !hasDescription) ||
        (!!bodyHidden && !!body && !textActive)
    );
  }, [description, hasDescription, bodyHidden, body, textActive]);

  return (
    <ActiveContainer
      className={`relative w-[240px] flex flex-col ${
        size === "normal" ? "h-[430px]" : "h-[250px]"
      } ${className}`}
      to={to}
      onChange={(active) => to && setLinkActive(active)}
    >
      <div className="relative overflow-hidden shrink-0">
        <Image
          src={thumbnailUrl(imgSrc, bodyHidden ? "/height/480" : "/height/240")}
          alt={imgAlt}
          className="transition-height duration-300"
          style={{
            height: body ? (bodyHidden && !textActive ? 300 : 140) : 300,
          }}
        />
        {overlayTo && (
          <Link
            to={overlayTo}
            className={`absolute left-0 top-0 w-full h-full flex flex-col items-center justify-center
                      text-white font-bold text-xl opacity-0 hover:opacity-100 focus:opacity-100 transition-opacity
                      hover:bg-black/40 focus:bg-black/40`}
            aria-label={title}
          >
            <PlayIcon />
            Video abspielen
          </Link>
        )}
      </div>
      <ActiveContainer
        className="flex flex-col h-full min-h-[95px] pt-3 bg-white p-3 cursor-default focus:outline-none"
        tabIndex={bodyHidden ? 0 : -1}
        onChange={(active) => bodyHidden && setTextActive(active)}
      >
        {!hideDescription && (
          <ItemDescription description={description} active={textActive} />
        )}
        <h3
          className={`shrink-0 text-red-400 text-lg font-bold my-1 leading-5
            ${(textActive && description) || linkActive ? "underline" : ""}
          `}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: `${titleLineClamp}`,
            overflow: "hidden",
          }}
          title={title}
        >
          {to ? (
            <Link to={to} className="focus:outline-none">
              <span ref={titleRef}>{title}</span>
            </Link>
          ) : (
            <span ref={titleRef}>{title}</span>
          )}
        </h3>
        {body && (
          <p
            className={`${textActive ? "underline" : ""} leading-5`}
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: `${bodyLineClamp}`,
              overflow: "hidden",
            }}
            title={body}
          >
            {body}
          </p>
        )}
      </ActiveContainer>
    </ActiveContainer>
  );
}

interface ItemDescriptionProps {
  description?: string;
  active: boolean;
}

function ItemDescription({ description, active }: ItemDescriptionProps) {
  return (
    <p
      className={`${ELLIPSED_TEXT} min-h-[25px] ${!active || "underline"}`}
      title={description}
    >
      {description}
    </p>
  );
}
