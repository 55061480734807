import { ReactNode, Children, useRef, useState } from "react";
import { debounce } from "src/lib/utils/debounce";
import SwiperRef from "swiper";
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as Caret } from "../assets/caret-right.svg";

export function SimpleSwiper({
  children,
  className,
  buttonStrokeClass,
}: {
  children: ReactNode;
  className?: string;
  buttonStrokeClass?: string;
}) {
  const [backShow, setBackShow] = useState(false);
  const [forwardShow, setForwardShow] = useState(false);
  const swiperRef = useRef<SwiperRef>();

  const handleProgress = (swiper: SwiperRef) => {
    const progress = swiper.progress;

    if (!progress) {
      backShow && setBackShow(false);
    } else {
      !backShow && setBackShow(true);
    }

    if (progress >= 1) {
      forwardShow && setForwardShow(false);
    } else {
      !forwardShow && setForwardShow(true);
    }
  };

  return (
    <div className={`flex ${className ? className : ""}`}>
      <NavButton
        back
        hidden={!(swiperRef.current?.allowSlidePrev && backShow)}
        buttonStrokeClass={buttonStrokeClass}
        onClick={() => swiperRef.current?.slidePrev()}
      />

      <Swiper
        className="mx-2"
        modules={[Navigation]}
        slidesPerView={"auto"}
        spaceBetween={10}
        onSwiper={(swiper: SwiperRef) => (swiperRef.current = swiper)}
        onProgress={debounce(handleProgress, 200)}
      >
        {Children.map(children, (child, i) => {
          return (
            <SwiperSlide onFocus={() => swiperRef.current?.slideTo(i)}>
              {child}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <NavButton
        hidden={!(swiperRef.current?.allowSlidePrev && forwardShow)}
        buttonStrokeClass={buttonStrokeClass}
        onClick={() => swiperRef.current?.slideNext()}
      />
    </div>
  );
}

function NavButton({
  back,
  hidden,
  buttonStrokeClass,
  onClick,
}: {
  back?: boolean;
  hidden: boolean;
  buttonStrokeClass?: string;
  onClick: () => void;
}) {
  return (
    <button
      className={`${hidden ? "invisible" : ""}`}
      onClick={(evt) => {
        evt.stopPropagation();
        onClick();
      }}
    >
      <Caret
        className={`${
          buttonStrokeClass ? buttonStrokeClass : "stroke-red-400"
        } w-[10px] ${back ? "rotate-180 mr-0.5" : "ml-0.5"}`}
      />
    </button>
  );
}
