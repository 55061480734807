import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useBreadcrumbsContext } from "../context/breadcrumbs.context";

export function Breadcrumb() {
  const { breadcrumbs } = useBreadcrumbsContext();

  return (
    <nav className="w-full text-2xs">
      <ul className="list-reset flex flex-wrap ml-[15px]">
        <li className="text-[13px] ml-[-15px]">
          <a
            href="https://www.bundesrat.de/DE/homepage/homepage-node.html"
            className="block pl-[5px] pb-3 h-[14px] w-[15px] indent-[-9000px] min-h-[20px] bg-icons bg-no-repeat bg-[left_0_top_-4199px]"
          >
            Home
          </a>
        </li>
        <LinkWrapper>
          <a
            href="https://www.bundesrat.de/DE/service/service-node.html"
            className="text-red-400 hover:underline font-bold"
          >
            Service
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <InternalLink to="/" last={!breadcrumbs.length}>
            Mediathek
          </InternalLink>
        </LinkWrapper>
        {breadcrumbs.map((crumb, i) => {
          return (
            <LinkWrapper key={crumb.url}>
              <InternalLink to={crumb.url} last={i === breadcrumbs.length - 1}>
                {crumb.title}
              </InternalLink>
            </LinkWrapper>
          );
        })}
      </ul>
    </nav>
  );
}

function LinkWrapper({ children }: { children: ReactNode }) {
  return (
    <li className="ml-[13px] pl-3 pb-[5px] text-[13px] bg-icons bg-no-repeat bg-[left_0_top_-4394px]">
      {children}
    </li>
  );
}

function InternalLink({
  children,
  to,
  last,
}: {
  children: ReactNode;
  to: string;
  last?: boolean;
}) {
  return last ? (
    <span className="font-bold">{children}</span>
  ) : (
    <Link to={to} className="text-red-400 hover:underline font-bold">
      {children}
    </Link>
  );
}
