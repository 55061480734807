import { CSSProperties, useState } from "react";
import { Download } from "src/types";
import { PrimaryButton } from "./button";
import { Popup } from "./popup";

interface PlenaryDownloadProps {
  meetingDownload?: Download;
  agendaItemDownload?: Download;
  speechesDownload?: Download[];
  otherVideoDownload?: Download;
  anchors?: Array<HTMLElement | null> | HTMLElement | null;
  handleCancel?: () => void;
  handleClickOutside?: () => void;
  className?: string;
}

const LINK_STYLES =
  "text-red-400 hover:underline focus:underline focus:outline-none";

export function PlenaryDownload({
  meetingDownload,
  agendaItemDownload,
  speechesDownload,
  otherVideoDownload,
  anchors,
  handleCancel,
  handleClickOutside,
  className = "",
}: PlenaryDownloadProps) {
  const [warningStyle, setWarningStyle] = useState<CSSProperties>({});
  const [contentStyle, setContentStyle] = useState<CSSProperties>({
    display: "none",
  });
  const [warningTimeout, setWarningTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  const handleAccept = () => {
    clearTimeout(warningTimeout);
    setWarningStyle({ left: "-100%", position: "absolute" });
    setContentStyle({ left: "100%" });
    window.requestAnimationFrame(() => {
      setContentStyle({ left: 0 });
    });
    const timeout = setTimeout(() => {
      setWarningStyle({ display: "none" });
    }, 500);
    setWarningTimeout(timeout);
  };

  return (
    <Popup
      className={`w-[375px] min-h-[160px] ${className}`}
      anchors={anchors}
      handleClickOutside={handleClickOutside}
    >
      <div
        className={`flex flex-col w-full h-full left-0 p-4 transition-out duration-500`}
        style={warningStyle}
      >
        <span>
          Mit dem Download des Videos erklären Sie sich mit den&nbsp;
          <a
            className={LINK_STYLES}
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.bundesrat.de/DE/service/mediathek/mediathek-nutzungsbedingungen.html"
          >
            Nutzungsbedingungen
          </a>
          &nbsp;des Bundesrates einverstanden.
        </span>
        <div className="flex items-center justify-between mt-2">
          <button className={LINK_STYLES} onClick={handleCancel}>
            Ablehnen
          </button>
          <PrimaryButton className="px-7 py-2" onClick={handleAccept}>
            Akzeptieren
          </PrimaryButton>
        </div>
      </div>

      <div
        className={`relative flex flex-col p-4 transition-out duration-500`}
        style={contentStyle}
      >
        <span className="mb-2 font-semibold">Herunterladen</span>
        {meetingDownload && <DownloadLink {...meetingDownload} />}
        {agendaItemDownload && <DownloadLink {...agendaItemDownload} />}
        {speechesDownload && speechesDownload.map((speech) =>
          <DownloadLink key={speech.url} {...speech} />
        )}
        {otherVideoDownload && <DownloadLink {...otherVideoDownload} />}
      </div>
    </Popup>
  );
}

function DownloadLink({ title, url }: Download) {
  return (
    <a className={`${LINK_STYLES} mt-2`} href={url}>
      {title}
    </a>
  );
}