import { MutableRefObject, createRef, useEffect, useRef, useState } from "react";

const POPUP_POSITION_STYLES_RESPONSIVE =
  "top-[calc(100%_-_1rem)] right-1/2 translate-x-1/2 -translate-y-full md:top-12 md:right-2 md:translate-x-0 md:translate-y-0 max-w-[calc(100%_-_1rem)]";

const POPUP_POSITION_STYLES = "top-12 right-2 max-w-[calc(100%_-_1rem)] z-50";

export function useShare(responsive = true) {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const shareBtnRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const desktopShareBtnRef = createRef<HTMLButtonElement>();
  const mobileShareBtnRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    shareBtnRefs.current = [desktopShareBtnRef.current, mobileShareBtnRef.current];
  }, [desktopShareBtnRef, mobileShareBtnRef]);
  // const desktopShareBtnRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;
  // const mobileShareBtnRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;

  const toggleSharePopup = () => {
    setShowSharePopup((show) => !show);
  };

  if (!responsive) {
    return {
      showSharePopup,
      shareBtnRef: desktopShareBtnRef,
      setShowSharePopup,
      toggleSharePopup,
      sharePosition: POPUP_POSITION_STYLES,
    };
  }

  return {
    showSharePopup,
    shareBtnRefs,
    desktopShareBtnRef,
    mobileShareBtnRef,
    setShowSharePopup,
    toggleSharePopup,
    sharePosition: responsive ? POPUP_POSITION_STYLES_RESPONSIVE : POPUP_POSITION_STYLES,
  };
}
