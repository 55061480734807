export const scrollToElementWithOffset = (
  parent: HTMLElement | null | undefined,
  element: HTMLElement | null | undefined,
  scrollMargin: number = 0
) => {
  if (!element || !parent) {
    return;
  }

  const eltPos = element.getBoundingClientRect().top;
  const offset =
    parent.scrollTop - parent.getBoundingClientRect().top - scrollMargin;

  parent.scrollTo({
    top: eltPos + offset,
    behavior: "smooth",
  });
};
