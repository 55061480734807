import { Spinner } from "src/components/spinner";
import { useEffect, useState } from "react";
import { getLiveSpecialEvent } from "src/api/videos.api";
import { KalturaPlayer, KalturaPlayerProvider, PlayerBundleConfig, usePlayerConfig } from "src/lib/kaltura-player";
import { Video } from "src/types";

const bundlerConfig: Partial<PlayerBundleConfig> = {
  bundlerUrl: process.env.REACT_APP_KALTURA_BASE_URL,
  partnerId: process.env.REACT_APP_KALTURA_PARTNER_ID,
  ks: "",
  uiConfId: process.env.REACT_APP_KALTURA_PLAYER_UICONF_ID,
};

const LIVESTREAM_ID = process.env.REACT_APP_KALTURA_LIVESTREAM_ENTRY_ID;

function Livestream() {
  const { entryId, customizeConfig } = usePlayerConfig(
    LIVESTREAM_ID, null
  );
  const [loading, setLoading] = useState(true);
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const [videoData, setVideoData] = useState<Video | null>(null);

  useEffect(() => {
    getLiveSpecialEvent()
      .then((videoNode) => {
        if (videoNode?.field_is_live) {
          setVideoData(videoNode);
          setShowPlayer(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
    {showPlayer && entryId && (
      <>
        <h2 className="text-2xl font-serif text-gray-900 mr-3 py-4">Livestream: {videoData?.title}</h2>
        <div className="relative">
          <div className="w-full" style={{ paddingBottom: "56.25%" }}>
            <KalturaPlayer
              className="absolute top-0 left-0 w-full h-full"
              entryId={entryId}
              autoplay={true}
              customizeConfig={customizeConfig}
            />
          </div>
        </div>
        <div className="flex justify-end items-center mt-5 pt-5 border-t-4 border-gray-400"></div>
      </>
    )}
    </>
  );
}

export default function SpecialEventLivestreamEmbed() {
  return (
    <KalturaPlayerProvider playerBundleConfig={bundlerConfig}>
      <Livestream />
    </KalturaPlayerProvider>
  );
}