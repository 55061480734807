import { createContext } from "react";
import { Agenda, Speech } from "src/types";

export interface AgendaProps {
  agenda: Agenda;
  associatedAgenda?: Agenda;
  agendaRef?: HTMLDivElement | null;
  scrollIntoView?: boolean;
}

export interface SpeechProps {
  agenda: Agenda;
  associatedAgenda?: Agenda;
  agendaRef: HTMLDivElement | null;
  speech: Speech;
}

export interface AgendasContextT {
  agendas: Agenda[];
  activeAgenda: Agenda | undefined;
  activeAssociatedAgenda: Agenda | undefined;
  liveAgenda: Agenda | undefined;
  recalledAgenda: Agenda | undefined;
  liveAssociatedAgenda: Agenda | undefined;
  activeSpeech: Speech | undefined;
  viewingLive: boolean;
  disabled: boolean;
  scrollIntoViewID: string | undefined;
  activeAgendaRef: HTMLDivElement | null | undefined;
  liveAgendaRef: HTMLDivElement | null | undefined;
  onAgendaClick: (props: AgendaProps) => void;
  onSpeechClick: (props: SpeechProps) => void;
  onRef: (live: boolean, ref: HTMLDivElement, resetActive: boolean) => void;
  onScrollFinish: () => void;
}

export const AgendasContext = createContext<AgendasContextT>({
  agendas: [],
  activeAgenda: undefined,
  activeAssociatedAgenda: undefined,
  liveAgenda: undefined,
  recalledAgenda: undefined,
  liveAssociatedAgenda: undefined,
  activeSpeech: undefined,
  viewingLive: true,
  disabled: false,
  scrollIntoViewID: undefined,
  activeAgendaRef: undefined,
  liveAgendaRef: undefined,
  onAgendaClick: () => {},
  onSpeechClick: () => {},
  onRef: () => {},
  onScrollFinish: () => {},
});
