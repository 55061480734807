import { useEffect, useRef, useState } from "react";
import { CategoryNav } from "src/components/category-nav";
import { Swimlane } from "src/components/swimlane";
import { ItemPreview } from "src/components/item-preview";
import { PlenaryPreview } from "src/components/plenary-preview";
import { CategorySwiper } from "src/components/category-swiper";
import { Category, PlenaryMeeting, TopLevelCategory, Video } from "src/types";
import { CategoryType } from "src/models/category.model";
import { absoluteURL } from "src/lib/utils/absolute-url";
import { stripHtml } from "src/lib/utils/strip-html";
import { getOtherVideos, getPlenaryMeetings } from "src/api/videos.api";
import { videoURL } from "src/lib/utils/video-url";
import { useBreadcrumbsContext } from "src/components/breadcrumbs/context/breadcrumbs.context";
import { Spinner } from "src/components/spinner";
import SpecialEventLivestreamEmbed from "src/embed/SpecialEventLivestreamEmbed";

interface HomePageProps {
  categories: Category[];
}

export default function HomePage({ categories }: HomePageProps) {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [topLevelCategories, setTopLevelCategories] = useState<
    TopLevelCategory[]
  >([]);
  const [eventCategories, setEventCategories] = useState<Category[]>([]);
  const [swiperCategories, setSwiperCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const swimlaneRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setBreadcrumbs([]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    const getVideos = async () => {
      setLoading(true);
      const { topLevelCategories, eventCategories } =
        mapCategoriesByLevel(categories);

      const videos = await Promise.all(
        topLevelCategories.map(({ category }) => {
          return category.name === CategoryType.Plenarsitzungen
            ? getPlenaryMeetings({ limit: 5 })
            : getOtherVideos({ categoryName: category.name, limit: 5 });
        })
      );

      setTopLevelCategories(
        topLevelCategories.map((data, index) => ({
          ...data,
          videos: videos[index],
        }))
      );
      setSwiperCategories(topLevelCategories.map(({ category }) => category));
      setEventCategories(eventCategories);
      swimlaneRefs.current = [];
      setLoading(false);
    };
    if (categories.length) {
      getVideos();
    }
  }, [categories]);

  const onCategoryClick = (i: number) => {
    const swimlane = swimlaneRefs.current[i];
    swimlane?.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => swimlane?.focus(), 500);
  };

  return (
    <div>
      <h1 className="mb-10 font-serif text-4xl text-center text-gray-900">
        Mediathek
      </h1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <SpecialEventLivestreamEmbed />
          <CategorySwiper
            className="mb-8 -mx-3 md:-mx-10"
            categories={swiperCategories}
            onClick={onCategoryClick}
          />
          <CategoryNav
            categories={swiperCategories}
            onClick={onCategoryClick}
          />

          {topLevelCategories.map(({ category, videos }, i) => {
            const hoverableFirstElt = [
              CategoryType.DerBundesrat,
              CategoryType.Interviews,
            ].includes(category.name as CategoryType);
            const rowHasDescriptions = videos
              ?.slice(hoverableFirstElt ? 1 : 0)
              .some((video) => video.field_videotype && !video.body?.processed);
            const rowHasDescriptionsAndBody = videos
              ?.slice(hoverableFirstElt ? 1 : 0)
              .some((video) => video.field_videotype && video.body?.processed);

            return (
              <Swimlane
                key={category.id}
                className="mt-8"
                category={category}
                ref={(r) => (swimlaneRefs.current[i] = r)}
              >
                {category.name === CategoryType.Veranstaltungen
                  ? eventCategories.map((category) => (
                      <ItemPreview
                        key={category.id}
                        title={category.name}
                        imgSrc={absoluteURL(
                          category.field_category_icon?.uri.url
                        )}
                        imgAlt={
                          category.field_category_icon?.resourceIdObjMeta?.alt
                        }
                        to={category.path.alias}
                      />
                    ))
                  : videos &&
                    videos.map((video, j) =>
                      category.name === CategoryType.Plenarsitzungen ? (
                        <PlenaryPreview
                          key={video.id}
                          meeting={video as PlenaryMeeting}
                        />
                      ) : (
                        <ItemPreview
                          key={video.id}
                          title={video.title}
                          description={video.field_videotype?.name}
                          hasDescription={
                            j
                              ? video.body?.processed
                                ? rowHasDescriptionsAndBody
                                : rowHasDescriptions
                              : undefined
                          }
                          body={stripHtml(video.body?.processed)}
                          bodyHidden={!j && hoverableFirstElt}
                          imgSrc={video.field_thumbnail_link?.uri}
                          imgAlt={`Vorschaubild zum Video "${video.title}"`}
                          overlayTo={videoURL(video as Video, category.id)}
                        />
                      )
                    )}
              </Swimlane>
            );
          })}
        </>
      )}
    </div>
  );
}

function mapCategoriesByLevel(categories: Category[]) {
  return categories.reduce<{
    topLevelCategories: TopLevelCategory[];
    eventCategories: Category[];
  }>(
    (acc, curr) => {
      if (curr.parent[0].id === "virtual") {
        if (curr.name === CategoryType.Aktuelles) {
          acc.topLevelCategories.unshift({
            category: curr,
          });
        } else {
          acc.topLevelCategories.push({
            category: curr,
          });
        }
      } else {
        acc.eventCategories.push(curr);
      }
      return acc;
    },
    { topLevelCategories: [], eventCategories: [] }
  );
}
