import { KalturaCategory } from "kaltura-typescript-client/api/types/KalturaCategory";
import { KalturaCategoryFilter } from "kaltura-typescript-client/api/types/KalturaCategoryFilter";
import { KalturaMediaEntry } from "kaltura-typescript-client/api/types/KalturaMediaEntry";
import { KalturaMediaEntryFilter } from "kaltura-typescript-client/api/types/KalturaMediaEntryFilter";
import { getCategories, getKalturaCategories } from "./categories.api";
import { getKalturaMediaEntries } from "./videos.api";
import axios from "axios";
import { getPlenaryMeetingByCms } from "./plenary-meetings.api";

const LEGACY_ID_TAG_PREFIX = "legacyid:";

export async function getLegacyRedirectUrl({cmsCategory, cmsVideo} : {cmsCategory: string | null, cmsVideo: string | null}) {
  return cmsCategory ? getLegacyCategoryRedirect(cmsCategory) : cmsVideo ? getLegacyVideoRedirect(cmsVideo) : undefined;
}

async function getLegacyCategoryRedirect(cmsCategory: string) {
  let category: KalturaCategory | undefined;
  const tagFilter = new KalturaCategoryFilter({
    tagsLike: `${LEGACY_ID_TAG_PREFIX}${cmsCategory}`,
  });
  category = (await getKalturaCategories(tagFilter) || [])[0];
  if (!category) {
    const referenceFilter = new KalturaCategoryFilter({
      referenceIdEqual: cmsCategory
    });
    category = (await getKalturaCategories(referenceFilter) || [])[0];
  }
  if (!category) {
    const idFilter = new KalturaCategoryFilter({
      idEqual: parseInt(cmsCategory, 10)
    });
    category = (await getKalturaCategories(idFilter) || [])[0];
  }
  if (!category) {
    return;
  }
  const findByCmsCategory = category.name.includes("Sitzung des Bundesrates");
  if (findByCmsCategory) {
    const meeting = await getPlenaryMeetingByCms(cmsCategory);
    return meeting?.path.alias;
  }
  const [drupalCategory] = await getCategories({ kmcId: category.id.toString(10) });
  return drupalCategory?.path.alias;
}

async function getLegacyVideoRedirect(cmsVideo: string) {
  let mediaEntry: KalturaMediaEntry | undefined;
  const tagFilter = new KalturaMediaEntryFilter({
    tagsLike: `${LEGACY_ID_TAG_PREFIX}${cmsVideo}`,
  });
  mediaEntry = (await getKalturaMediaEntries(tagFilter) || [])[0];
  if (!mediaEntry) {
    const referenceFilter = new KalturaMediaEntryFilter({
      referenceIdEqual: cmsVideo
    });
    mediaEntry = (await getKalturaMediaEntries(referenceFilter) || [])[0];
  }
  if (!mediaEntry) {
    const idFilter = new KalturaMediaEntryFilter({
      idEqual: cmsVideo
    });
    mediaEntry = (await getKalturaMediaEntries(idFilter) || [])[0];
  }
  if (!mediaEntry) {
    return;
  }
  const redirect = await getDrupalEntryRedirect(mediaEntry.id);
  return redirect;
}

async function getDrupalEntryRedirect(kmcId: string) {
  const { data: { uuid, path_alias } } = await axios.get(
    `${process.env.REACT_APP_DRUPAL_BASE_URL}/api/getredirectinfo/${kmcId}`,
  );
  if (path_alias === null) {
    return undefined;
  }
  return uuid === null ? path_alias : `${path_alias}?id=${uuid}`;
}
