import { useContext } from "react";
import { Category } from "src/types";
import { Dropdown, DropdownContext } from "./dropdown";
import { Swiper } from "./swiper";

export function CategoryNav({
  categories,
  onClick,
}: {
  categories: Category[];
  onClick: (index: number) => void;
}) {
  return (
    <>
      <Dropdown className="sm:hidden" label="Rubriken">
        {categories?.map((category, i) => {
          return (
            <DropwdownCategoryItem
              key={`category_link_${category.id}`}
              category={category}
              onClick={() => onClick(i)}
            />
          );
        })}
      </Dropdown>
      <Swiper className="hidden sm:block" scrollbar>
        {categories?.map((category, i) => {
          return (
            <CategoryItem
              key={`category_link_${category.id}`}
              category={category}
              onClick={() => onClick(i)}
            />
          );
        })}
      </Swiper>
    </>
  );
}

const className = `flex items-center justify-center text-center text-lg bg-red-400 text-white
hover:font-semibold focus:font-semibold focus:outline-none p-4 cursor-pointer`;

function CategoryItem({
  category,
  onClick,
}: {
  category: Category;
  onClick: () => void;
}) {
  return (
    <button
      className={`w-[180px] h-[80px] ${className} hover:border-2 focus:border-2 border-red-100`}
      onClick={onClick}
    >
      {category.name}
    </button>
  );
}

function DropwdownCategoryItem({
  category,
  onClick,
}: {
  category: Category;
  onClick: () => void;
}) {
  const {
    selected,
    selectedId,
    onClick: dropdownClick,
  } = useContext(DropdownContext);

  return (
    <button
      id={selected === category.id ? selectedId : undefined}
      className={`w-full ${className} border-2 border-red-400 hover:border-red-100 focus:border-red-100`}
      role="option"
      aria-selected={selected === category.name}
      onClick={() => {
        dropdownClick(category.name);
        onClick();
      }}
    >
      {category.name}
    </button>
  );
}
