import { ReactNode, Children } from "react";
import { Swiper } from "./swiper";

export function VideoGallery({ children }: { children: ReactNode }) {
  if (!Children.count(children)) {
    return <p>In dieser Rubrik existieren noch keine Videos.</p>;
  }

  return <Swiper scrollbar>{children}</Swiper>;
}
