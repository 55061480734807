import React, { ForwardedRef } from "react";
import { ReactComponent as ShareIcon } from "../assets/share.svg";

interface ShareButtonProps {
  className?: string;
  overlayed?: boolean;
  handleClick: () => void;
}

export const ShareButton = React.forwardRef(
  ({ className = "", overlayed, handleClick }: ShareButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        className={`flex justify-center items-center w-9 h-9 border-none ${overlayed ? 'bg-black bg-opacity-50 hover:bg-opacity-75 focus:bg-opacity-75' : 'bg-transparent'} ${className}`}
        aria-label="Teilen"
        onClick={() => handleClick()}
      >
        <ShareIcon />
      </button>
    );
  }
);
