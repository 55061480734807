export function PlayIcon({
  widthAndHeightClass = "w-[70px] h-[70px]",
  iconSize = 40,
  borderColorClass = "border-white",
  borderWidthClass = "border-2",
  fillColorClass = "fill-white",
  className,
}: {
  widthAndHeightClass?: string;
  iconSize?: number;
  borderColorClass?: string;
  borderWidthClass?: string;
  fillColorClass?: string;
  className?: string;
}) {
  return (
    <div
      className={`${widthAndHeightClass} flex items-center justify-center ${borderWidthClass} ${borderColorClass} rounded-[50%] bg-white/25 mb-2 ${
        className ? className : ""
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 460.5 531.74"
        overflow="visible"
        enableBackground="new 0 0 460.5 531.74"
      >
        <polygon
          className={`${fillColorClass}`}
          points="0.5,0.866 459.5,265.87 0.5,530.874"
          style={{ transform: "translateX(15%)" }}
        />
      </svg>
    </div>
  );
}
