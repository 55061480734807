import { Link } from "react-router-dom";
import { ActiveContainer } from "./active-container";
import { ReactComponent as Caret } from "../assets/caret-right.svg";
import { CSSProperties, useState } from "react";

export function CategoryLink({
  to,
  textColorClass = "text-red-400",
  textHoverColorClass = "text-white",
  borderColorClass = "border-red-400",
  bgHoverColorClass = "bg-red-400",
  strokeColorClass = "stroke-red-400",
  strokeHoverColorClass = "stroke-white",
  className,
}: {
  to: string;
  textColorClass?: string;
  textHoverColorClass?: string;
  borderColorClass?: string;
  bgHoverColorClass?: string;
  strokeColorClass?: string;
  strokeHoverColorClass?: string;
  className?: string;
}) {
  const [styleTimeout, setStyleTimeout] =
    useState<ReturnType<typeof setTimeout>>();
  const [normalStyle, setNormalStyle] = useState<CSSProperties>({
    left: "0px",
  });
  const [overlayStyle, setOverlayStyle] = useState<CSSProperties>({
    left: "-100%",
  });
  const text = "Alle Videos";
  const sharedStyle =
    "flex items-center pl-4 pr-2.5 py-1.5 transition-all duration-300 pointer-events-none";
  const caretSharedStyle = "w-[12px] ml-1.5 mt-0.5 pointer-events-none";

  const handleActive = (active: boolean) => {
    clearTimeout(styleTimeout);

    if (active) {
      setNormalStyle({ left: "100%" });
      setOverlayStyle({ left: 0 });

      const timeout = setTimeout(() => {
        setNormalStyle({ left: 0, transitionProperty: "none" });
      }, 300);
      setStyleTimeout(timeout);
    } else {
      setNormalStyle({ left: 0 });
      setOverlayStyle({ opacity: 0 });

      const timeout = setTimeout(() => {
        setOverlayStyle({ left: "-100%", transitionProperty: "none" });
      }, 300);
      setStyleTimeout(timeout);
    }
  };

  return (
    <ActiveContainer
      className={`flex ${className ? className : ""}`}
      onChange={handleActive}
    >
      <Link
        to={to}
        className={`relative ${textColorClass} font-semibold overflow-hidden outline-none`}
      >
        <span
          className={`relative border ${borderColorClass} ${sharedStyle}`}
          style={normalStyle}
        >
          {text}
          <Caret className={`${strokeColorClass} ${caretSharedStyle}`} />
        </span>
        <span
          className={`absolute top-0 border ${borderColorClass} ${textHoverColorClass} ${bgHoverColorClass} ${sharedStyle}`}
          style={overlayStyle}
        >
          {text}
          <Caret className={`${strokeHoverColorClass} ${caretSharedStyle}`} />
        </span>
      </Link>
    </ActiveContainer>
  );
}
