import { Outlet } from "react-router-dom";
import { KalturaPlayerProvider, PlayerBundleConfig } from "src/lib/kaltura-player";
import { Breadcrumb } from "./breadcrumbs/components/breadcrumb";
import { BreadcrumbsProvider } from "./breadcrumbs/context/breadcrumbs.context";

const bundlerConfig: PlayerBundleConfig = {
  bundlerUrl: process.env.REACT_APP_KALTURA_BASE_URL,
  partnerId: process.env.REACT_APP_KALTURA_PARTNER_ID,
  ks: "",
  uiConfId: process.env.REACT_APP_KALTURA_PLAYER_UICONF_ID,
};

export function Layout() {
  return (
    <div className="max-w-screen-xl px-3 md:px-10 mx-auto">
      <BreadcrumbsProvider>
        <header className="pt-4">
          <Breadcrumb />
        </header>

        <main className="container py-10 mx-auto">
          <KalturaPlayerProvider playerBundleConfig={bundlerConfig}>
            <Outlet />
          </KalturaPlayerProvider>
        </main>
      </BreadcrumbsProvider>
    </div>
  );
}
