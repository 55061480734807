import { useCallback, useEffect, useRef } from "react";

export function useIframeHeight() {
  const target = useRef(document.querySelector("body"));
  const debounceRef = useRef<NodeJS.Timeout | undefined>();

  const onMutation = useCallback(() => {
    window.clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      const height = target.current!.getBoundingClientRect().height;
      const parent = window.parent;
      const message = JSON.stringify({ height });
      parent.postMessage(message, "*");
    }, 50);
  }, []);

  useEffect(() => {
    const config = {
      attributes: true,
      attributeOldValue: false,
      characterData: true,
      characterDataOldValue: false,
      childList: true,
      subtree: true,
    };
    const observer = new MutationObserver(onMutation);
    observer.observe(target.current!, config);
    window.addEventListener("resize", onMutation);
    return () => {
      observer.disconnect();
      window.removeEventListener("resize", onMutation);
    };
  }, [onMutation]);
}
