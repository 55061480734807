import { ActiveContainer } from "../../active-container";
import { useContext, useEffect, useRef, useState } from "react";
import { Agenda as AgendaType } from "src/types";
import { AgendaItem } from "./agenda-item";
import { AgendasContext } from "../context/agendas.context";

export function Agenda({
  agenda,
  className,
}: {
  agenda: AgendaType;
  className?: string;
}) {
  const {
    agendas,
    activeAgenda,
    activeAssociatedAgenda,
    liveAgenda,
    viewingLive,
    recalledAgenda,
    disabled,
    onAgendaClick,
    onSpeechClick,
  } = useContext(AgendasContext);
  const [active, setActive] = useState(false);
  const [isActiveContainer, setIsActiveContainer] = useState(false);
  const [isLiveContainer, setIsLiveContainer] = useState(false);
  const [isDisabledContainer, setIsDisabledContainer] = useState(false);
  const [disabledAgendaActions, setDisabledAgendaActions] = useState(false);
  const [isLastAgenda, setIsLastAgenda] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsLastAgenda(agendas[agendas.length - 1].id === agenda.id);
  }, [agendas, agenda]);

  useEffect(() => {
    const isActiveContainer =
      agenda.id === activeAgenda?.id &&
      (!activeAssociatedAgenda ||
        !!agenda.field_associated_with.find(
          (ag) => ag.id === activeAssociatedAgenda?.id
        ));
    setIsActiveContainer(isActiveContainer);
  }, [agenda, agendas, activeAgenda, activeAssociatedAgenda]);

  useEffect(() => {
    if (disabled) {
      return setIsDisabledContainer(true);
    }

    if (recalledAgenda && liveAgenda && recalledAgenda.id === agenda.id) {
      setIsLiveContainer(recalledAgenda.id === liveAgenda?.id);
      setIsDisabledContainer(recalledAgenda.id !== liveAgenda?.id);
      return;
    }

    const liveIndex = agendas.findIndex((ag) => ag.id === liveAgenda?.id);
    if (liveIndex !== -1) {
      const agendaIndex = agendas.findIndex((ag) => ag.id === agenda.id);
      setIsLiveContainer(agenda.id === liveAgenda?.id);
      setIsDisabledContainer(liveIndex < agendaIndex);
    } else {
      setIsLiveContainer(false);
      setIsDisabledContainer(false);
    }

    setDisabledAgendaActions(activeAgenda?.id === agenda.id);
  }, [agenda, agendas, liveAgenda, activeAgenda, recalledAgenda, disabled]);

  useEffect(() => {
    if (isLiveContainer) {
      return active || isActiveContainer || viewingLive
        ? setBgColor("!bg-red-900")
        : setBgColor("!bg-red-400");
    }

    if (isActiveContainer) {
      return setBgColor("!bg-white");
    }

    if (agenda.field_green_list) {
      return active
        ? setBgColor("!bg-green-500/20")
        : setBgColor("!bg-green-300");
    }

    return active ? setBgColor("!bg-white") : setBgColor("");
  }, [
    active,
    agenda.field_green_list,
    isActiveContainer,
    isLiveContainer,
    viewingLive,
  ]);

  const handleAgendaClick = (recalledAgenda?: AgendaType) => {
    onAgendaClick({
      agenda: recalledAgenda ? recalledAgenda : agenda,
      agendaRef: containerRef.current,
      scrollIntoView: true,
    });
  };

  return (
    <ActiveContainer
      className={`shrink-0 ${bgColor} ${className ? className : ""}`}
      onChange={(active) =>
        setActive(active && !agenda.field_deposed && !isDisabledContainer)
      }
      ref={containerRef}
    >
      <AgendaItem
        agenda={agenda}
        activeContainer={isActiveContainer}
        liveContainer={isLiveContainer}
        greenListed={!!agenda.field_green_list}
        deposed={!!agenda.field_deposed}
        containerDisabled={isDisabledContainer}
        agendaActionsDisabled={disabledAgendaActions}
        firstItem={true}
        lastItem={!agenda.field_associated_with.length}
        onlyItem={!agenda.field_associated_with.length}
        parentRef={containerRef.current}
        onAgendaClick={handleAgendaClick}
        onSpeechClick={(agenda, speech, agendaRef) =>
          onSpeechClick({ agenda, agendaRef, speech })
        }
      />
      {agenda.field_associated_with.map((associatedAgenda, i) => (
        <AgendaItem
          key={`${agenda.id}_${associatedAgenda.id}}`}
          agenda={associatedAgenda}
          activeContainer={isActiveContainer}
          liveContainer={isLiveContainer}
          greenListed={!!agenda.field_green_list}
          deposed={!!agenda.field_deposed}
          containerDisabled={isDisabledContainer}
          agendaActionsDisabled={disabledAgendaActions}
          lastItem={agenda.field_associated_with.length - 1 === i}
          onlyItem={!agenda.field_associated_with.length}
          parentRef={containerRef.current}
          onAgendaClick={handleAgendaClick}
          onSpeechClick={(associatedAgenda, speech, agendaRef) =>
            onSpeechClick({ agenda, associatedAgenda, agendaRef, speech })
          }
        />
      ))}
      {agenda.id !== recalledAgenda?.id && (!isLastAgenda || recalledAgenda) && (
        <div className="flex md:hidden pt-3">
          <hr className="w-full border-t-gray-100 mx-5" />
        </div>
      )}
    </ActiveContainer>
  );
}
