import { useCallback, useEffect, useRef, useState } from "react";

export function usePlayerConfig(entryId: string | null | undefined, signLanguageEntryId: string | null | undefined) {
  const signLanguageActive = useRef(false);
  const [activeEntryId, setActiveEntryId] = useState(entryId);
  const normalEntryId = useRef(entryId);
  const signEntryId = useRef(signLanguageEntryId);

  useEffect(() => {
    normalEntryId.current = entryId;
    signEntryId.current = signLanguageEntryId;
    setActiveEntryId(signLanguageActive.current && normalEntryId.current ? signEntryId.current : normalEntryId.current);
  }, [entryId, signLanguageEntryId]);

  const toggleSignLanguage = useCallback(() => {
    signLanguageActive.current = !signLanguageActive.current;
    setActiveEntryId(signLanguageActive.current ? signEntryId.current : normalEntryId.current);
  }, []);

  const getTooltipText = useCallback(() => {
    return signLanguageActive.current ? "Gebärdensprache aus" : "Gebärdensprache an";
  }, []);

  const customizeConfig = useCallback(
    (config: any) => {
      if (!signLanguageEntryId) {
        return config;
      }
      // @ts-ignore
      const h = window.KalturaPlayer.ui.preact.h;
      const tooltip = h(
        "span",
        {
          class: "playkit-tooltip-label playkit-tooltip-top-left playkit-hide",
          style: "max-width: 240px;",
        },
        getTooltipText()
      );
      const button = h(
        "button",
        {
          class: "playkit-control-button playkit-button-badge",
          onClick: () => {
            toggleSignLanguage();
          },
          onmouseenter: (event: any) => {
            const span = event.target.nextElementSibling;
            span.classList.remove("playkit-hide");
            span.classList.add("playkit-show");
            span.textContent = getTooltipText();
          },
          onmouseleave: (event: any) => {
            const span = event.target.nextElementSibling;
            span.classList.add("playkit-hide");
            span.classList.remove("playkit-show");
            span.textContent = getTooltipText();
          },
          style: { display: "inline-block", width: 30, height: 30, padding: "3px" },
        },
        h(
          "svg",
          {
            viewBox: "0 0 512 512",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "white",
          },
          h("path", {
            d: "M216 260c0 15.464-12.536 28-28 28s-28-12.536-28-28c0-44.112 35.888-80 80-80s80 35.888 80 80c0 15.464-12.536 28-28 28s-28-12.536-28-28c0-13.234-10.767-24-24-24s-24 10.766-24 24zm24-176c-97.047 0-176 78.953-176 176 0 15.464 12.536 28 28 28s28-12.536 28-28c0-66.168 53.832-120 120-120s120 53.832 120 120c0 75.164-71.009 70.311-71.997 143.622L288 404c0 28.673-23.327 52-52 52-15.464 0-28 12.536-28 28s12.536 28 28 28c59.475 0 107.876-48.328 108-107.774.595-34.428 72-48.24 72-144.226 0-97.047-78.953-176-176-176zm268.485-52.201L480.2 3.515c-4.687-4.686-12.284-4.686-16.971 0L376.2 90.544c-4.686 4.686-4.686 12.284 0 16.971l28.285 28.285c4.686 4.686 12.284 4.686 16.97 0l87.03-87.029c4.687-4.688 4.687-12.286 0-16.972zM168.97 314.745c-4.686-4.686-12.284-4.686-16.97 0L3.515 463.23c-4.686 4.686-4.686 12.284 0 16.971L31.8 508.485c4.687 4.686 12.284 4.686 16.971 0L197.256 360c4.686-4.686 4.686-12.284 0-16.971l-28.286-28.284z",
          })
        )
      );
      const tooltipContainer = h(
        "div",
        {
          class: "playkit-tooltip",
        },
        button,
        tooltip
      );
      const controlContainer = h(
        "div",
        {
          class: "playkit-control-button-container playkit-control-settings",
        },
        tooltipContainer
      );

      const newConfig = {
        ...config,
        ui: {
          ...(config.ui || {}),
          uiComponents: [
            ...((config.ui || {}).uiComponents || []),
            {
              label: "Sign language toggle",
              presets: ["Playback", "Live"],
              container: "BottomBarRightControls",
              get: () => controlContainer,
            },
          ],
        },
      };
      return newConfig;
    },
    [getTooltipText, toggleSignLanguage, signLanguageEntryId]
  );

  return {
    entryId: activeEntryId,
    customizeConfig,
  };
}
