export function formatDate(input: string): string {
  const date = new Date(input);
  return date.toLocaleDateString("de-DE", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

export function formatTime(input: Date): string {
  return input.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit"
  })
}

export function formatDay(input: string): string {
  const date = new Date(input);

  return date.toLocaleDateString("de-DE", {
    weekday: "long",
  });
}

export function upateStarttime(date: string, time: number): Date {
  const updatedDate = new Date(date);
  updatedDate.setHours(Math.floor(time / 3600));
  updatedDate.setMinutes(Math.floor(time % 3600 / 60));

  return updatedDate;
}
