import { CSSProperties } from "react";
import NoImage from "../assets/no-image.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function Image({
  src,
  alt,
  className,
  style,
}: {
  src: string | undefined;
  alt: string | undefined;
  className?: string;
  style?: CSSProperties;
}) {
  // BRLIVE-345: Load fallback image if original image is not available (= person left Bundesrat)
  const fedStatePath = "laender"
  const fallbackSrc: string | undefined = src?.replace(`/${fedStatePath}/`, "/laender-ausgeschieden/");

  return (
    <LazyLoadImage src={src || NoImage}
      alt={src ? alt : "Kein Bild verfügbar"}
      className={`${className} ${
        !src
          ? `!bg-gray-100 !object-contain !object-center !scale-100 ${
              !className?.includes("w-") ? "!w-full" : ""
            }`
          : ""
      }`}
      style={style}
      onError={e => {
        const target = e.target as HTMLImageElement;
        if (fallbackSrc && fallbackSrc.indexOf(fedStatePath) !== -1) {
          target.src = fallbackSrc;
        } else {
          target.src = NoImage;
        }
      }}
    />
  );
}
