import axios from "axios";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { Agenda, Speech } from "src/types";

export async function getAgendas(ids: string[]): Promise<Agenda[]> {
  const params = new DrupalJsonApiParams();
  params.addFilter("id", ids, "IN");
  params.addInclude([
    "field_speeches.field_fedstate",
    "field_associated_with",
    "field_see_also",
  ]);

  let {
    data: { data, included, links },
  } = await axios.get(
    `${
      process.env.REACT_APP_DRUPAL_BASE_URL
    }/jsonapi/node/agenda_item?${params.getQueryString()}`
  );

  const getNextPage = async (
    links: { next?: { href: string } } | undefined
  ) => {
    const next = links?.next;
    if (next) {
      const {
        data: { data: pageData, included: pageIncluded, links: pageLinks },
      } = await axios.get(next.href);
      data.push(...pageData);
      if (pageIncluded) {
        included ? included.push(...pageIncluded) : (included = pageIncluded);
      }
      await getNextPage(pageLinks);
    }
  };

  await getNextPage(links);

  const agendas: Agenda[] = data.map(
    ({
      type,
      id,
      attributes,
      relationships: { field_associated_with, field_see_also, field_speeches },
    }: any) => ({
      type,
      id,
      ...attributes,
      field_associated_with: mapAgendaRelations(
        field_associated_with.data,
        included
      ),
      field_see_also: mapAgendaRelations(field_see_also.data, included),
      field_speeches: mapSpeechRelations(field_speeches.data, included),
    })
  );

  agendas.sort(sortAgendas);

  return agendas;
}

const mapAgendaRelations = (data: any[], included: any[]) => {
  const relations = data.map((relation) => {
    const {
      type,
      id,
      attributes,
      relationships: { field_see_also, field_speeches },
    } = included.find((item) => item.id === relation.id);

    return {
      type,
      id,
      ...attributes,
      field_associated_with: [],
      field_see_also: field_see_also.data.map((relation: any) => {
        const { id, attributes } = included.find(
          (item) => item.id === relation.id
        );

        return { id, ...attributes };
      }),
      field_speeches: mapSpeechRelations(field_speeches.data, included),
    };
  });

  relations.sort(sortAgendas);

  return relations;
};

const mapSpeechRelations = (data: any[], included: any[]) => {
  const speeches = data.map((relation) => {
    const {
      type,
      id,
      attributes,
      relationships: { field_fedstate },
    } = included.find((item) => item.id === relation.id);

    return {
      type,
      id,
      ...attributes,
      field_fedstate: field_fedstate.data
        ? mapFedState(
            included.find((item) => item.id === field_fedstate.data.id)
          )
        : null,
    };
  });

  speeches.sort(sortSpeeches);

  return speeches;
};

const mapFedState = ({ type, id, attributes }: any) => {
  return { type, id, ...attributes };
};

const sortAgendas = (a: Agenda, b: Agenda) => {
  const aFieldItemNumber = a.field_item_number ?? "";
  const bFieldItemNumber = b.field_item_number ?? "";
  if (a.field_order !== null && b.field_order !== null) {
    return (
      a.field_order - b.field_order ||
      aFieldItemNumber.localeCompare(bFieldItemNumber, undefined, {
        numeric: true,
      })
    );
  } else if (a.field_order === null && b.field_order === null) {
    return aFieldItemNumber.localeCompare(bFieldItemNumber, undefined, {
      numeric: true,
    });
  } else if (a.field_order !== null) {
    return String(a.field_order).localeCompare(bFieldItemNumber, undefined, {
      numeric: true,
    });
  } else {
    return aFieldItemNumber.localeCompare(String(b.field_order), undefined, {
      numeric: true,
    });
  }
};

const sortSpeeches = (a: Speech, b: Speech) => {
  if (a.field_order !== null && b.field_order !== null) {
    return a.field_order - b.field_order;
  } else if (a.field_order === null && b.field_order === null) {
    return 0;
  } else if (a.field_order === null) {
    return 1;
  } else {
    return -1;
  }
};
