import { Spinner } from "src/components/spinner";
import { OutsideLink } from "src/components/outside-link";
// import { PlenaryDownload } from "src/components/plenary-download/plenary-download";
import { PlenaryDownload } from "src/components/plenary-download";
import { Agendas } from "src/components/agenda/agendas";
import { DownloadButton } from "src/components/download-button";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getPlenaryMeeting } from "src/api/plenary-meetings.api";
import { formatDate } from "src/lib/utils/format-date";
import { Agenda as AgendaType, PlenaryMeeting, Speech, Video, Category, ActiveEntry } from "src/types";
import { useBreadcrumbsContext } from "src/components/breadcrumbs/context/breadcrumbs.context";
import { PlenaryShare } from "src/components/plenary-share";
import { ShareButton } from "src/components/share-button";
import { KalturaPlayer, usePlayer, usePlayerConfig } from "src/lib/kaltura-player";
import PlenaryLivestreamEmbed from "src/embed/PlenaryLivestreamEmbed";
import { MeetingStatus } from "src/models/meeting-status.model";
import { UpcomingMeeting } from "src/components/upcoming-meeting";
import { useDownload } from "src/lib/utils/use-download";
import { useShare } from "src/lib/utils/use-share";

const REACH_TIME = 15 * 60 * 1000;

interface PlenaryMeetingPageProps {
  plenaryCategory: Category | undefined;
}

export default function PlenaryMeetingPage({ plenaryCategory }: PlenaryMeetingPageProps) {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const { pathname } = useLocation();
  const { title } = useParams();
  const [searchParams] = useSearchParams();
  const [activeId, setActiveId] = useState<string | undefined>();
  const [meeting, setMeeting] = useState<PlenaryMeeting>();
  const [upcoming, setUpcoming] = useState(true);
  const [showLivestream, setShowLivestream] = useState(false);
  const {
    // downloads,
    setDownloads,
    meetingDownload,
    agendaItemDownload,
    speechesDownload,
    otherVideoDownload,
    showDownloadButton,
    showDownloadPopup,
    toggleDownloadPopup,
    setShowDownloadPopup,
    downloadBtnRefs,
    desktopDownloadBtnRef,
    mobileDownloadBtnRef,
    downloadPosition,
  } = useDownload(meeting);
  // const downloadBtnRefs = useRef([desktopDownloadBtnRef, mobileDownloadBtnRef]);
  const { showSharePopup, setShowSharePopup, toggleSharePopup, shareBtnRefs, desktopShareBtnRef, mobileShareBtnRef, sharePosition } =
    useShare();
  // const shareBtnRefs = useRef([desktopShareBtnRef, mobileShareBtnRef]);
  const [activeEntry, setActiveEntry] = useState<ActiveEntry>();
  const { entryId, customizeConfig } = usePlayerConfig(activeEntry?.field_kmc_id, activeEntry?.field_sign_kmc_id);
  const [playerId, setPlayerId] = useState("");
  const { playerPause } = usePlayer(playerId);

  useEffect(() => {
    const breadcrumbs = [];
    if (plenaryCategory) {
      breadcrumbs.push({
        title: plenaryCategory.name,
        url: plenaryCategory.path.alias,
      });
    }
    breadcrumbs.push({ title: meeting?.title || "", url: pathname });
    setBreadcrumbs(breadcrumbs);
  }, [setBreadcrumbs, pathname, meeting, plenaryCategory]);

  useEffect(() => {
    (async () => {
      if (title) {
        const meeting = await getPlenaryMeeting(title);
        setMeeting(meeting);
        setActiveEntry(meeting);
        setUpcoming(meeting.field_meeting_status === MeetingStatus.Geplant);
      }
    })();
  }, [title]);

  useEffect(() => {
    setActiveId(searchParams.get("id") ?? undefined);
  }, [searchParams]);

  useEffect(() => {
    setShowLivestream(
      !upcoming && !!meeting?.field_meeting_status && meeting.field_meeting_status !== MeetingStatus.Beendet
    );
  }, [upcoming, meeting?.field_meeting_status]);

  const handleAgendaClick = (agenda: AgendaType) => {
    setDownloads({ agenda });
    setActiveEntry(agenda);
    emitScrollMessage();
  };

  const handleSpeechClick = (speech: Speech, agenda: AgendaType) => {
    setDownloads({ agenda });
    setActiveEntry(speech);
    emitScrollMessage();
  };

  const handleVideoClick = (video: Video) => {
    setDownloads({ video });
    setActiveEntry(video);
    emitScrollMessage();
  };

  const countdownReached = useCallback(() => {
    setUpcoming(false);
  }, []);

  const emitScrollMessage = () => {
    window.parent.postMessage("scrollIntoView", "*");
  };

  const handlePlayerLoaded = (data: { playerId: string }) => {
    const { playerId } = data;
    if (!playerId) {
      return;
    }
    setPlayerId(playerId);
  }

  const handleDownloadClick = () => {
    toggleDownloadPopup();
    playerPause()
  }

  if (!meeting) {
    return <Spinner />;
  }

  return (
    <div>
      <h1 className="flex flex-col sm:block mb-10 font-serif text-3xl text-center text-gray-900">
        {meeting.title ? meeting.title + " " : ""}
        {meeting.field_plenary_meeting_date && (
          <span>
            <span className="hidden sm:inline"> am </span>
            {formatDate(meeting.field_plenary_meeting_date)}
          </span>
        )}
      </h1>
      {showLivestream ? (
        <PlenaryLivestreamEmbed />
      ) : (
        <div className="flex flex-col md:flex-row -mx-3 md:mx-0">
          <div className="w-full md:w-1/2">
            <div className="relative w-full pb-[56.25%] md:mb-4">
              <div
                className={`absolute w-full h-full ${
                  upcoming ? "flex items-center justify-center bg-livestream bg-cover" : ""
                }`}
              >
                {upcoming ? (
                  <UpcomingMeeting
                    date={meeting.field_plenary_meeting_date}
                    time={meeting.field_plenary_meeting_starttime}
                    countdownReached={countdownReached}
                    reachTime={REACH_TIME}
                  />
                ) : (
                  entryId && <KalturaPlayer entryId={entryId} customizeConfig={customizeConfig} onPlayerLoaded={handlePlayerLoaded} />
                )}
              </div>
              {!upcoming && (
                <>
                  {showDownloadButton && (
                    <>
                      <DownloadButton
                        ref={desktopDownloadBtnRef}
                        overlayed
                        className="hidden md:flex absolute top-2 right-12 z-50"
                        handleClick={handleDownloadClick}
                      />
                      {showDownloadPopup && (
                        <PlenaryDownload
                          className={downloadPosition}
                          // anchors={downloadBtnRefs.current}
                          anchors={downloadBtnRefs?.current}
                          meetingDownload={meetingDownload}
                          agendaItemDownload={agendaItemDownload}
                          speechesDownload={speechesDownload}
                          otherVideoDownload={otherVideoDownload}
                          // downloads={downloads}
                          handleClickOutside={() => setShowDownloadPopup(false)}
                          handleCancel={() => setShowDownloadPopup(false)}
                        ></PlenaryDownload>
                      )}
                    </>
                  )}
                  <ShareButton
                    ref={desktopShareBtnRef}
                    overlayed
                    className="hidden md:flex absolute top-2 right-2 z-50"
                    handleClick={toggleSharePopup}
                  />
                  {showSharePopup && (
                    <PlenaryShare
                      className={sharePosition}
                      // anchors={shareBtnRefs.current}
                      anchors={shareBtnRefs?.current}
                      entry={activeEntry}
                      showEmbed={!upcoming}
                      handleClickOutside={() => setShowSharePopup(false)}
                      handleCancel={() => setShowSharePopup(false)}
                    ></PlenaryShare>
                  )}
                </>
              )}
            </div>
            <div className="hidden md:flex flex-col">
              <div className="flex items-center justify-between pb-1 border-b border-dotted cursor-default">
                <h2 className="text-xl">Mehr zur Sitzung</h2>
              </div>

              {meeting.field_complete_agenda?.uri && (
                <OutsideLink className="mt-8 text-xs" href={meeting.field_complete_agenda.uri}>
                  Detaillierte Tagesordnung
                </OutsideLink>
              )}
              {meeting.field_blog_link?.uri && (
                <OutsideLink className="mt-4 text-xs" href={meeting.field_blog_link?.uri}>
                  BundesratKOMPAKT
                </OutsideLink>
              )}
              {meeting.field_meeting_protocol_link?.uri && (
                <OutsideLink className="mt-4 text-xs" href={meeting.field_meeting_protocol_link?.uri}>
                  Plenarprotokoll
                </OutsideLink>
              )}
            </div>
          </div>
          <div className="relative w-full md:w-1/2">
            <Agendas
              agendas={meeting.field_agenda}
              otherVideosBeforeAgenda={meeting.field_video_others}
              otherVideosAfterAgenda={meeting.field_video_others_after ?? []}
              activeId={activeId}
              disableActions={upcoming}
              videoActions={
                <div className="flex">
                  {showDownloadButton && (
                    <DownloadButton className="mr-2" ref={mobileDownloadBtnRef} handleClick={toggleDownloadPopup} />
                  )}
                  <ShareButton ref={mobileShareBtnRef} handleClick={toggleSharePopup} />
                </div>
              }
              onAgendaClick={handleAgendaClick}
              onSpeechClick={handleSpeechClick}
              onVideoClick={handleVideoClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}
