import { LinkItem } from "src/types";

export function entityDownload(entity?: {
    title: string,
    field_deactivate_download: boolean | null,
    field_download_link: LinkItem | null,
  } | null) {
  if (!entity || !entity.field_download_link || entity.field_deactivate_download) {
    return;
  }
  return { 
    title: entity.title,
    url: entity.field_download_link.uri,
  };
}