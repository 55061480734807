export function thumbnailUrl(
  path: string | undefined,
  appendage?: string
): string | undefined {
  if (!path) {
    return undefined;
  }

  if (path.includes(".kaltura.com")) {
    return `${path}${appendage || ""}`;
  }

  return path;
}