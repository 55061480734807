import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Category, PlenaryMeeting, Video } from "src/types";
import { CategoryType } from "src/models/category.model";
import { ItemPreview } from "src/components/item-preview";
import { getOtherVideos, getPlenaryMeetings } from "src/api/videos.api";
import { VideoSwiper } from "src/components/video-swiper";
import { PlenaryPreview } from "src/components/plenary-preview";
import { stripHtml } from "src/lib/utils/strip-html";
import { getKalturaCategories } from "src/api/categories.api";
import { videoURL } from "src/lib/utils/video-url";
import { useBreadcrumbsContext } from "src/components/breadcrumbs/context/breadcrumbs.context";
import { Spinner } from "src/components/spinner";

interface CategoryPageProps {
  categories: Category[];
}

export default function CategoryPage({ categories }: CategoryPageProps) {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const { pathname } = useLocation();
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    setBreadcrumbs([{ title: categoryName, url: pathname }]);
  }, [setBreadcrumbs, pathname, categoryName]);

  useEffect(() => {
    const category = categories.find((c) => c.path.alias === pathname);
    setCategoryName(category?.name ?? "");
    setCategoryId(category?.id ?? "");
  }, [categories, pathname]);

  const isPlenary = categoryName === CategoryType.Plenarsitzungen;

  return (
    <>
      <h1 className="text-4xl font-serif text-gray-900 text-center mb-10">{categoryName}</h1>
      {categoryName &&
        (isPlenary ? <PlenaryDisplay /> : <DefaultDisplay categoryId={categoryId} categoryName={categoryName} />)}
    </>
  );
}

function PlenaryDisplay() {
  const [years, setYears] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlenaryMeetingYears = async () => {
      const categories = await getKalturaCategories({
        parentIdIn: process.env.REACT_APP_KALTURA_PLENARYMEETING_CATEGORY_ID as string,
        depthEqual: 1,
      });
      const years = categories?.map((obj) => parseInt(obj.name, 10));
      years?.sort((a, b) => b - a);
      const selectedYear = years?.[0] ?? null;
      setYears(years || []);
      setSelectedYear(selectedYear);
      if (selectedYear === null) {
        setLoading(false);
      }
    };
    fetchPlenaryMeetingYears();
  }, []);

  useEffect(() => {
    let subscribed = true;
    if (!selectedYear) {
      return;
    }
    const fetchVideos = async () => {
      setLoading(true);
      const videos = await getPlenaryMeetings({ meetingYear: selectedYear });
      if (subscribed) {
        setVideos(videos);
        setLoading(false);
      }
    };
    fetchVideos().catch(() => setLoading(false));

    return () => {
      subscribed = false;
    };
  }, [selectedYear]);

  return (
    <>
      <Timeline years={years} selectedYear={selectedYear} handleChange={setSelectedYear} />
      <VideoList videos={videos} loading={loading} isPlenary />
    </>
  );
}

interface TimelineProps {
  years: number[];
  selectedYear: number | null;
  handleChange: (year: number) => void;
}

function Timeline({ years, selectedYear, handleChange }: TimelineProps) {
  if (!years.length) {
    return null;
  }

  return (
    <>
      <ul className="hidden md:flex flex-wrap gap-4 mb-6 border-b-4 border-gray-400">
        {years.map((year) => {
          return (
            <li key={year} className="relative -mb-1">
              <TimelineButton selected={year === selectedYear} handleChange={() => handleChange(year)}>
                {year}
              </TimelineButton>
            </li>
          );
        })}
      </ul>
      <select
        name="Jahre"
        className="md:hidden py-2 px-3 text-xl font-bold text-red-400"
        value={selectedYear || ""}
        onChange={(e) => handleChange(+e.target.value)}
      >
        {years.map((year) => (
          <option key={year} value={year} className="text-base font-normal text-gray-900">
            {year}
          </option>
        ))}
      </select>
    </>
  );
}

interface TimelineButtonProps {
  children?: ReactNode;
  selected: boolean;
  handleChange: () => void;
}

function TimelineButton({ children, selected, handleChange }: TimelineButtonProps) {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const isActive = selected || hovered || focused;

  return (
    <>
      <button
        className={`pb-2 px-4 border-b-4 border-b-transparent text-xl font-bold ${
          isActive ? "text-red-400 !border-b-gray-900" : "cursor-default"
        }`}
        onClick={() => handleChange()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {children}
      </button>
      {isActive && (
        <span className="absolute bottom-0 left-1/2 transform translate-y-full -translate-x-1/2 border-solid border-t-gray-900 border-t-8 border-x-transparent border-x-8 border-b-0 pointer-events-none"></span>
      )}
    </>
  );
}

interface DefaultDisplayProps {
  categoryId?: string;
  categoryName?: string;
}

function DefaultDisplay({ categoryId, categoryName }: DefaultDisplayProps) {
  const [videos, setVideos] = useState<(Video | PlenaryMeeting)[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let subscribed = true;
    if (!categoryName) {
      setVideos([]);
      setLoading(false);
      return;
    }
    const fetchVideos = async () => {
      setLoading(true);
      const videos = await getOtherVideos({ categoryName });
      if (subscribed) {
        setVideos(videos);
        setLoading(false);
      }
    };
    fetchVideos().catch(() => setLoading(false));

    return () => {
      subscribed = false;
    };
  }, [categoryName]);

  return (
    <>
      {!loading && !!videos.length && <VideoSwiper videos={videos} scrollIntoView />}
      <VideoList videos={videos} categoryId={categoryId} loading={loading} />
    </>
  );
}

interface VideoListProps extends DefaultDisplayProps {
  videos: (Video | PlenaryMeeting)[];
  loading?: boolean;
  isPlenary?: boolean;
}

function VideoList({ videos, categoryId, isPlenary, loading }: VideoListProps) {
  if (loading) {
    return (
      <div className="py-4">
        <Spinner />
      </div>
    );
  }

  if (!videos.length) {
    return <p className="py-4">In dieser Rubrik existieren noch keine Videos.</p>;
  }

  return (
    <div className="flex flex-wrap gap-4 py-4">
      {videos.map((video) =>
        isPlenary ? (
          <PlenaryPreview
            key={video.id}
            meeting={video as PlenaryMeeting}
            className="!w-full !h-[250px] md:!w-[calc((100%_-_2rem)_/_3)] lg:!w-[calc((100%_-_3rem)_/_4)]"
            imageSize="/height/240"
          />
        ) : (
          <ItemPreview
            key={video.id}
            title={video.title}
            body={stripHtml(video.body?.processed) || " "}
            imgSrc={video.field_thumbnail_link?.uri}
            imgAlt={video.field_thumbnail_link?.title}
            overlayTo={videoURL(video as Video, categoryId)}
            size="small"
            className="!w-full md:!w-[calc((100%_-_2rem)_/_3)] lg:!w-[calc((100%_-_3rem)_/_4)]"
          />
        )
      )}
    </div>
  );
}
