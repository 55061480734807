import { useEffect, useMemo, useState } from "react";
import { absoluteURL } from "src/lib/utils/absolute-url";
import { stripHtml } from "src/lib/utils/strip-html";
import { Category } from "src/types";
import { CategoryLink } from "./category-link";
import { Swiper } from "./swiper";

export function CategorySwiper({
  className = "",
  categories,
  onClick,
}: {
  className?: string;
  categories: Category[];
  onClick: (index: number) => void;
}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [overlayTitle, setOverlayTitle] = useState("");
  const [overlayDescription, setOverlayDescription] = useState("");
  const [overlayLinkTo, setOverlayLinkTo] = useState("");
  const [overlayClick, setOverlayClick] = useState<() => void>();
  const [showOverlay, setShowOverlay] = useState<true | undefined>(undefined);

  useEffect(() => {
    const category = categories[activeSlide];
    if (!category) {
      return;
    }
    setOverlayTitle(category.name);
    setOverlayDescription(stripHtml(category.description?.processed));
    setOverlayLinkTo(category.path.alias);
    setOverlayClick(() => () => onClick(activeSlide));
    setShowOverlay(true);
  }, [activeSlide, categories, onClick]);

  const previewItems = useMemo(() => {
    return categories?.map((category) => {
      return (
        <img
          key={`category_swiper_item_preview_${category.id}`}
          className="h-[400px] w-full"
          src={absoluteURL(category.field_category_icon?.uri.url)}
          alt={category.field_category_icon?.resourceIdObjMeta?.alt}
          aria-hidden
        />
      );
    })
  }, [categories]);

  return (
    <div className={className}>
      <Swiper
        hero
        overlay={
          showOverlay && (
            <Overlay
              title={overlayTitle}
              description={overlayDescription}
              to={overlayLinkTo}
              handleClick={overlayClick}
            />
          )
        }
        previewItems={previewItems}
        onSlideChange={(index) => setActiveSlide(index || 0)}
      >
        {categories?.map((category) => (
          <Slide
            key={`category_swiper_item_${category.id}`}
            background={absoluteURL(category.field_category_icon?.uri.url)}
          />
        ))}
      </Swiper>
    </div>
  );
}

function Slide({ background }: { background: string | undefined }) {
  return (
    <div
      className="h-[400px] w-full px-8 pt-14 pb-20 !bg-cover !bg-center !bg-no-repeat"
      style={{
        background: `url(${background})`,
      }}
    ></div>
  );
}

interface OverlayProps {
  title: string;
  description?: string;
  to?: string;
  handleClick?: () => void;
}

function Overlay({
  title,
  description,
  to,
  handleClick,
}: OverlayProps) {
  return (
    <div className="absolute w-full left-1/2 transform -translate-x-1/2 md:left-10 md:translate-x-0 top-1/2 -translate-y-1/2 pointer-events-none">
      <div className="flex flex-col w-full max-w-[390px] h-[270] p-5 m-auto md:-m-0 md:ml-8 lg:m-0 bg-black/50 text-white">
        <p className="shrink-0 font-bold text-[35px] py-1 mb-3 border-t-4 border-b whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </p>
        {description &&
          (handleClick ? (
            <button
              className="text-left hover:underline focus:underline focus:outline-none clamp-4 pointer-events-auto"
              onClick={handleClick}
            >
              {description}
            </button>
          ) : (
            <p className="line-clamp-4">{description}</p>
          ))}
        {to && (
          <CategoryLink
            to={to}
            textColorClass="text-white"
            textHoverColorClass="text-black"
            borderColorClass="border-white"
            bgHoverColorClass="bg-white"
            strokeColorClass="stroke-white"
            strokeHoverColorClass="stroke-black"
            className="inline-flex self-start mt-auto pointer-events-auto"
          />
        )}
      </div>
    </div>
  );
}
