export function ReloadIcon({
  className,
  fillClass = "fill-black",
}: {
  className?: string;
  fillClass?: string;
}) {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17px"
      height="14px"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <g transform="matrix(1 0 0 1 -252 -238 )">
        <path
          className={fillClass}
          d="M 15.908619358669295 6.847788009224636  C 15.908619358669295 5.90707153848435  15.581258896398804 
                5.035525396474973  15.02474602293995 4.340363592729397  C 14.831967053702556 4.098267442171239  14.879252461251337 3.7593328313898127  
                15.141140872290844 3.589865525999105  C 15.381205249077016 3.434232286354576  15.704928423834087 3.486110032902752  15.879520697860425 3.7039965684050946  
                C 16.672460609063336 4.689673752820461  17.105303955086903 5.948573735722898  16.97799708860951 7.311229211721679  C 16.728800669121597 9.95147537647147  
                14.3507548374367 11.942390951439913  11.579586867893397 12.025915540793592  C 11.579586867893397 12.025915540793592  11.398318997851401 12.028645631169267  
                11.398318997851401 12.028645631169267  L 7.542739596432236 12.028645631169267  L 7.542739596432236 14  L 3.541666666666667 11.509868165687495  
                L 7.542739596432236 9.019736331374988  L 7.542739596432236 10.991090700205723  C 7.542739596432236 10.991090700205723  11.412868354020343 10.991090700205723  
                11.412868354020343 10.991090700205723  C 13.809874782840136 10.991090700205723  15.90498207115603 9.126950340907884  15.908619358669295 6.847788009224636  
                Z M 5.6016810021486005 1.9713543688307342  L 9.45726038689666 1.9713543688307342  L 9.45726038689666 0  L 13.458333333333332 2.4901318343125056  L 9.45726038689666 
                4.980263668625011  L 9.45726038689666 3.008909299794277  C 9.45726038689666 3.008909299794277  5.5871316459796585 3.008909299794277  5.5871316459796585 
                3.008909299794277  C 3.190125217160026 3.008909299794277  1.0950179288439679 4.873049659092117  1.0913805898018532 7.152211990775364  C 1.0913805898018532 
                8.09292846151565  1.4187411036011972 8.964474603525028  1.9752539770600492 9.659636407270602  C 2.168032946297444 9.90173255782876  2.1207475387486645 
                10.240667168610187  1.8588591277091573 10.410134474000897  C 1.6187947509229823 10.565767713645423  1.2950715761659146 10.513889967097247  1.1204793021395765 
                10.296003431594906  C 0.3275393909366642 9.310326247179539  -0.1053039550869054 8.04796774784056  0.022002911390491896 6.688770788278322  C 0.2711993308784031 
                4.0485246235285315  2.6492451625632993 2.0576090485600855  5.420413132106603 1.9740844592064062  C 5.420413132106603 1.9740844592064062  5.6016810021486005 
                1.9713543688307342  5.6016810021486005 1.9713543688307342  Z "
          fillRule="nonzero"
          stroke="none"
          transform="matrix(1 0 0 1 252 238 )"
        />
      </g>
    </svg>
  );
}
