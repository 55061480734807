import { Agenda, CurrentAgendaItem } from "src/types";

export interface AgendaState {
  agendas: Agenda[];
  currentAgendaItem: CurrentAgendaItem | undefined;
  previousAgendaItem: CurrentAgendaItem | undefined;
}

export enum AgendaActions {
  "LoadAgendas" = "LoadAgendas",
  "LoadCurrentItem" = "LoadCurrentItem",
}

export function agendaReducer(
  state: AgendaState,
  {
    type,
    payload,
  }: {
    type: AgendaActions;
    payload?: Agenda[] | CurrentAgendaItem;
  }
) {
  switch (type) {
    case AgendaActions.LoadAgendas:
      state = {
        ...state,
        agendas: payload as Agenda[],
        previousAgendaItem: undefined,
      };
      break;
    case AgendaActions.LoadCurrentItem:
      state = {
        ...state,
        currentAgendaItem: payload as CurrentAgendaItem,
        previousAgendaItem: state.currentAgendaItem,
      };
  }
  return state;
}

export const initialAgendaState: AgendaState = {
  agendas: [],
  currentAgendaItem: undefined,
  previousAgendaItem: undefined,
};
