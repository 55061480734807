import { ReactNode, useEffect, useRef } from "react";
import { OptionalRefObject } from "src/types";

interface PopupProps {
  children: ReactNode;
  backdrop?: boolean;
  anchors?: Array<OptionalRefObject> | OptionalRefObject;
  containers?: Array<OptionalRefObject> | OptionalRefObject;
  className?: string;
  handleClickOutside?: () => void;
}

export function Popup({
  children,
  backdrop = false,
  anchors,
  containers,
  className = "",
  handleClickOutside,
}: PopupProps) {
  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!handleClickOutside) {
      return;
    }
    const clickOutside = (event: Event) => {
      const path = event.composedPath();
      let clickInside = false;
      if (!containers) {
        clickInside = !!popupRef.current && path.includes(popupRef.current);
      } else if (Array.isArray(containers)) {
        clickInside = containers?.some((container) => !!container?.current && path.includes(container.current));
      } else {
        clickInside = !!containers?.current && path.includes(containers.current);
      }
      let clickAnchor = false;
      if (Array.isArray(anchors)) {
        clickAnchor = anchors?.some((anchor) => !!anchor?.current && path.includes(anchor.current));
      } else {
        clickAnchor = !!anchors?.current && path.includes(anchors.current);
      }
      if (!clickInside && !clickAnchor) {
        handleClickOutside();
      }
    };
    const timeout = setTimeout(() => {
      window.addEventListener("click", clickOutside);
      window.addEventListener("focusin", clickOutside);
    });
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("click", clickOutside);
      window.removeEventListener("focusin", clickOutside);
    };
  }, [handleClickOutside, anchors, containers]);

  return (
    <>
      {/* {backdrop && <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[9999]"></div>}
      <div ref={popupRef} className={`absolute z-[9999] ${className}`}>
        {children}
      </div> */}
      <div
        ref={popupRef}
        className={`absolute bg-white border border-gray-100 z-[100] overflow-hidden ${className}`}
      >
        {children}
      </div>
    </>
  );
}
