import { Video } from "src/types";

export function videoURL(video: Video, categoryId?: string): string | undefined {
  if (!video) {
    return;
  }
  const category = (categoryId && video.field_category.find((c) => c.id === categoryId)) ?? video.field_category[0];
  if (!category) {
    return;
  }
  return `${category.path.alias}?id=${video.id}`;
}
