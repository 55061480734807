import { useLoadPlayer } from "./inner-hooks/use-load-player";
import { PlayerStatuses } from "./kaltura-player-context";

export interface KalturaPlayerProps {
  /**
   * Tailwind styling for player div.
   * @default "w-full h-auto"
   */
  className?: string;
  /**
   * Entry Id, playable media entry id.
   */
  entryId: string;
  /**
   * Autoplay. Indicating if the auto play selected media
   * @default false
   */
  autoplay?: boolean;
  /**
   * OnPlayerLoaded event handler. Will be called after all player bundler scripts were loaded
   * @param {entryId: string, playerId: string}
   */
  onPlayerLoaded?: (data: { entryId: string; playerId: string }) => void;
  /**
   * OnMediaLoaded event handler. Will be called after media entry was successful loaded in player
   * @param entryId
   */
  onMediaLoaded?: (entryId: string) => void;
  /**
   * onPlayerLoadingError event handler. Will be called after a player loading related error
   * @param error
   */
  onPlayerLoadingError?: (entryId: string) => void;
  /**
   * onMediaLoadingError event handler. Will be called after a media loading related error
   * @param error
   */
  onMediaLoadingError?: (entryId: string) => void;

  /**
   * customize player config before player setup
   */
  customizeConfig?: (config: Record<string, any>) => Record<string, any>;
}

export const PlayerErrorMessage = "Oops, failed to load the player";

export const KalturaPlayer = ({ 
  className = "w-full h-auto",
  entryId,
  autoplay = false,
  onPlayerLoadingError,
  onPlayerLoaded,
  customizeConfig,
  onMediaLoadingError,
  onMediaLoaded
}: KalturaPlayerProps) => {

  const { playerId, playerStatus } = useLoadPlayer({
    autoplay,
    entryId,
    onPlayerLoadingError,
    onPlayerLoaded,
    onMediaLoadingError,
    onMediaLoaded,
    customizeConfig
  });

  return (
    <>
      {playerStatus === PlayerStatuses.Error ? (
        <div className="relative flex flex-col justify-evenly w-full h-full bg-gray-100">
          <div className="w-full text-gray-900 text-sm text-center tracking-normal leading-normal font-normal">
            {PlayerErrorMessage}
          </div>
        </div>
      ) : (
        <div id={playerId} className={className} />
      )}
    </>
  );
};