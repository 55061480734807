import { formatDate, formatDay, formatTime, upateStarttime } from "src/lib/utils/format-date";
import { Countdown } from "src/components/countdown";
import { useEffect, useState } from "react";

interface UpcomingMeetingProps {
  date: string | null;
  time: number | 34200; // Number of seconds from midnight (default: 09:30 AM)
  reachTime?: number;
  countdownReached?: () => void;
}

export function UpcomingMeeting({ date, time, reachTime = 0, countdownReached }: UpcomingMeetingProps) {
  const [countdownDate, setCountdownDate] = useState<Date>();

  useEffect(() => {
    if (date) {
      setCountdownDate(upateStarttime(date, time));
    }
  }, [date]);

  if (!date || !countdownDate) {
    return null;
  }
  return (
    <div className="md:absolute md:left-[10%] md:bottom-[12.5%] flex flex-col min-w-[300px] p-5 bg-black/40">
      <p className="px-2 py-1 mb-3 border-b-[3px] !text-white !text-[16px]">Nächste Plenarsitzung</p>
      <h2 className="!font-thin leading-6 px-2 pb-1 border-b !border-white !border-solid !text-white !text-[22px]">
        <span className="block !text-white !text-[22px]">{formatDay(date)},</span>
        {formatDate(date)}, ab {formatTime(countdownDate)} Uhr
      </h2>
      <Countdown
        className="mt-1.5 px-2 !text-white !text-[16px]"
        long
        date={countdownDate}
        reachTime={reachTime}
        reached={countdownReached}
      />
    </div>
  );
}
