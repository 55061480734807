export enum CategoryType {
  "Aktuelles" = "Aktuelles",
  "Plenarsitzungen" = "Plenarsitzungen",
  "DerBundesrat" = "Der Bundesrat",
  "Veranstaltungen" = "Veranstaltungen",
  "Interviews" = "Interviews",
  "WieGehtDeutschland" = "Wie geht Deutschland?",
  "ServiceAngeboteDesBundesrates" = "Service-Angebote des Bundesrates",
  "TagDerDeutschenEinheit" = "Tag der Deutschen Einheit",
  "TagDerOffenenTuer" = "Tag der offenen Tür",
  "JugendImBundesrat" = "Jugend im Bundesrat",
  "Sonderveranstaltungen" = "Sonderveranstaltungen",
}
