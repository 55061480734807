import { useState } from "react";
import { Link } from "react-router-dom";
import { MeetingStatus } from "src/models/meeting-status.model";
import { formatDate } from "../lib/utils/format-date";
import { PlenaryMeeting } from "../types";
import { ActiveContainer } from "./active-container";
import { Image } from "./image";
import { LivestreamPreview } from "./livestream-preview";
import { thumbnailUrl } from "src/lib/thumbnail-url";

export function PlenaryPreview({
  meeting,
  className = "",
  imageSize,
}: {
  meeting: PlenaryMeeting;
  className?: string;
  imageSize?: string;
}) {
  const [active, setActive] = useState(false);

  return meeting.field_meeting_status === MeetingStatus.Geplant ||
    meeting.field_meeting_status === MeetingStatus.Live ? (
    <LivestreamPreview meeting={meeting} to={meeting.path.alias} />
  ) : (
    <ActiveContainer
      className={`relative w-[240px] h-[430px] overflow-hidden ${className}`}
      onChange={setActive}
    >
      <div className="relative overflow-hidden h-[calc(100%_-_100px)]">
        <Image
          src={thumbnailUrl(
            meeting.field_thumbnail_link?.uri,
            `${imageSize ? imageSize : "/type/3/width/360/height/495"}`
          )}
          alt={`Vorschaubild zur ${meeting.title}`}
          className={`w-[240px] h-full ${
            active && meeting.field_thumbnail_link?.uri
              ? "motion-safe:animate-upscale"
              : ""
          }`}
        />
        {meeting.field_thumbnail_link?.uri && (
          <div
            className={`absolute left-0 top-0 w-full h-full bg-white opacity-0 ${
              active ? "motion-safe:animate-flash" : ""
            }`}
          />
        )}
      </div>
      <div className="h-[100px] flex flex-col justify-center bg-white px-4">
        <p className={`font-bold text-gray-700 ${active ? "underline" : ""}`}>
          {formatDate(meeting.field_plenary_meeting_date || meeting.created)}
        </p>
        <h3
          className={`text-red-400 text-lg font-bold line-clamp-2 ${
            active ? "underline" : ""
          }`}
        >
          <Link to={meeting.path.alias} className="focus:outline-none">
            {meeting.title}
          </Link>
        </h3>
      </div>
      <Link
        to={meeting.path.alias}
        className="absolute w-full h-full opacity-0 left-0 top-0"
        tabIndex={-1}
        aria-hidden
      />
    </ActiveContainer>
  );
}
