import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { isiOSdevice } from "src/lib/utils/is-iOS-device.util";

export const ActiveContainer = forwardRef(
  (
    {
      onChange,
      className,
      to,
      tabIndex,
      children,
    }: {
      onChange?: (active: boolean) => void;
      className?: string;
      to?: string | undefined;
      tabIndex?: number;
      children: ReactNode;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);
    const [keyboardFocus, setKeyboardFocus] = useState(false);
    const [isApple, setIsApple] = useState(false);

    useEffect(() => {
      setIsApple(isiOSdevice());

      const addFocus = (evt: KeyboardEvent) => {
        if (evt.key === "Tab") {
          setKeyboardFocus(true);
        }
      };
      const removeFocus = () => setKeyboardFocus(false);

      window.addEventListener("click", removeFocus);
      window.addEventListener("keydown", addFocus);

      return () => {
        window.removeEventListener("click", removeFocus);
        window.removeEventListener("keydown", addFocus);
      };
    }, []);

    const handleHover = (hovered: boolean) => {
      setHovered(hovered);
      handleChange(hovered || (focused && keyboardFocus));
    };

    const handleFocus = (focused: boolean) => {
      if (isApple) {
        return;
      }
      setFocused(focused);
      handleChange(hovered || (focused && keyboardFocus));
    };

    const handleChange = (active: boolean) => {
      onChange && onChange(active);
    };

    return (
      <div
        className={`${className || ""} ${to ? "relative" : ""}`}
        tabIndex={tabIndex}
        onMouseOver={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onFocusCapture={() => handleFocus(true)}
        onBlurCapture={() => handleFocus(false)}
        ref={ref}
      >
        {children}
        {to && (
          <Link
            to={to}
            className="absolute w-full h-full opacity-0 left-0 top-0"
            tabIndex={-1}
            aria-hidden
          />
        )}
      </div>
    );
  }
);
