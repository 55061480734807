import { KalturaClient } from "kaltura-typescript-client";
import { KalturaSessionType } from "kaltura-typescript-client/api/types/KalturaSessionType";
import { SessionStartAction } from "kaltura-typescript-client/api/types/SessionStartAction";

const config = {
  clientTag: process.env.REACT_APP_DRUPAL_CLIENT_ID as string,
  endpointUrl: process.env.REACT_APP_KALTURA_BASE_URL as string,
};

const kalturaSessionConfig = {
  secret: process.env.REACT_APP_KALTURA_SECRET as string,
  userId: process.env.REACT_APP_KALTURA_USER as string,
  type: KalturaSessionType.admin,
  partnerId: parseInt(process.env.REACT_APP_KALTURA_PARTNER_ID as string, 10),
};

export async function initKalturaSession() {
  const ks = (await kaltura.request(
    new SessionStartAction(kalturaSessionConfig)
  )) as string;
  kaltura.setDefaultRequestOptions({ ks });
}

export const kaltura = new KalturaClient(config);
