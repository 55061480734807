import React, { ReactNode, useState } from "react";
import { Breadcrumb } from "src/types";

const BreadcrumbsContext = React.createContext<{
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
} | undefined>(undefined);

export const useBreadcrumbsContext = () => {
    const context = React.useContext(BreadcrumbsContext);
    if (!context) {
        throw new Error("'useBreadcrumbsContext' must be used inside 'BreadcrumbsProvider'")
    }
    return context;
};

interface BreadcrumbsProviderProps {
  children: ReactNode;
}

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>;
};
