import { useEffect, useRef, useState } from "react";
import { Swiper } from "src/components/swiper";
import { PlayIcon } from "src/components/play-icon";
import { Image } from "src/components/image";
import { PlenaryMeeting, Video } from "src/types";
import { useSearchParams } from "react-router-dom";
import { KalturaPlayer, usePlayer, usePlayerConfig } from "src/lib/kaltura-player";
import { DownloadButton } from "./download-button";
// import { PlenaryDownload } from "./plenary-download/plenary-download";
import { PlenaryDownload } from "./plenary-download";
import { ShareButton } from "./share-button";
import { PlenaryShare } from "./plenary-share";
import { useDownload } from "src/lib/utils/use-download";
import { useShare } from "src/lib/utils/use-share";
import { thumbnailUrl } from "src/lib/thumbnail-url";

interface VideoSwiperProps {
  videos: (Video | PlenaryMeeting)[];
  scrollIntoView?: boolean;
}

export function VideoSwiper({ videos, scrollIntoView = false }: VideoSwiperProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayedVideoIndex, setDisplayedVideoIndex] = useState<number>(-1);
  const [displayedVideo, setDisplayedVideo] = useState<Video | PlenaryMeeting | null>(null);
  const {
    // downloads,
    meetingDownload,
    showDownloadButton,
    showDownloadPopup,
    toggleDownloadPopup,
    setShowDownloadPopup,
    downloadBtnRef,
    // desktopDownloadBtnRef,
    downloadPosition,
  } = useDownload(displayedVideo, false);
  const { showSharePopup, setShowSharePopup, toggleSharePopup, shareBtnRef, /*desktopShareBtnRef,*/ sharePosition } = useShare(false);
  const videoRef = useRef<HTMLDivElement | null>(null);
  const { entryId, customizeConfig } = usePlayerConfig(displayedVideo?.field_kmc_id, displayedVideo?.field_sign_kmc_id);
  const [playerId, setPlayerId] = useState("");
  const { playerPause } = usePlayer(playerId);

  useEffect(() => {
    const videoId = searchParams.get("id");
    const videoIndex = videoId ? videos.findIndex((video) => video.id === videoId) : 0;
    setDisplayedVideoIndex(videoIndex);
    setDisplayedVideo(videos[videoIndex] ?? null);
    if (scrollIntoView && videoId) {
      videoRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [videos, searchParams, scrollIntoView]);

  const handlePlayerLoaded = (data: { playerId: string }) => {
    const { playerId } = data;
    if (!playerId) {
      return;
    }
    setPlayerId(playerId);
  }

  const handleDownloadClick = () => {
    toggleDownloadPopup();
    playerPause()
  }

  const onSlideChange = (index?: number) => {
    if (index !== undefined) {
      setSearchParams({ id: videos[index].id });
    }
  };

  return (
    <div className="relative" ref={videoRef}>
      <Swiper
        slidesPerView={1}
        activeSlide={displayedVideoIndex}
        onSlideChange={onSlideChange}
        navigation
        position
        positionItemLabel="Videos"
      >
        {videos.map((video) => (
          <div
            key={video.id}
            className="relative w-full cursor-pointer"
            style={{ paddingBottom: "56.25%" }}
          >
            <Image
              src={thumbnailUrl(video.field_thumbnail_link?.uri, "/height/393")}
              alt={video.field_thumbnail_link?.title}
              className="absolute left-0 top-0 w-full h-auto max-w-full max-h-full"
            />
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20">
              <PlayIcon />
            </span>
          </div>
        ))}
      </Swiper>
      {entryId && (
        <>
          <div className="absolute left-0 top-0 w-full" style={{ paddingBottom: "56.25%" }}>
            <KalturaPlayer
              className="absolute top-0 left-0 w-full h-full"
              entryId={entryId}
              autoplay={false}
              customizeConfig={customizeConfig}
              onPlayerLoaded={handlePlayerLoaded}
            />
          </div>
          {showDownloadButton && (
            <>
              <DownloadButton
                // ref={desktopDownloadBtnRef}
                ref={downloadBtnRef}
                overlayed
                className="flex absolute top-2 right-12 z-50"
                handleClick={handleDownloadClick}
              />
              {showDownloadPopup && (
                <PlenaryDownload
                  className={downloadPosition}
                  // anchors={desktopDownloadBtnRef}
                  anchors={downloadBtnRef?.current}
                  meetingDownload={meetingDownload}
                  // downloads={downloads}
                  handleClickOutside={() => setShowDownloadPopup(false)}
                  handleCancel={() => setShowDownloadPopup(false)}
                ></PlenaryDownload>
              )}
            </>
          )}
          <ShareButton
            // ref={desktopShareBtnRef}
            ref={shareBtnRef}
            overlayed
            className="flex absolute top-2 right-2 z-50"
            handleClick={toggleSharePopup}
          />
          {showSharePopup && (
            <PlenaryShare
              className={sharePosition}
              // anchors={desktopShareBtnRef}
              anchors={shareBtnRef?.current}
              entry={displayedVideo}
              handleClickOutside={() => setShowSharePopup(false)}
              handleCancel={() => setShowSharePopup(false)}
            ></PlenaryShare>
          )}
        </>
      )}
      <h2 className="text-2xl font-serif text-gray-900 my-3 overflow-hidden text-ellipsis">{displayedVideo?.title}</h2>
      <div
        className="mb-16 overflow-hidden"
        dangerouslySetInnerHTML={{
          __html: displayedVideo?.body?.processed ?? "",
        }}
      ></div>
    </div>
  );
}
