import { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { Layout } from "src/components/layout";
import { Category } from "src/types";
import smoothscroll from "smoothscroll-polyfill";

import HomePage from "src/pages/Home";
import CategoryPage from "src/pages/Category";
import PlenaryMeetingPage from "src/pages/PlenaryMeeting";
import { getCategories } from "src/api/categories.api";
import { getLegacyRedirectUrl } from "src/api/legacy.api";
import { CategoryType } from "src/models/category.model";
import { useIframeHeight } from "src/lib/utils/use-iframe-height";

const CMS_CATEGORY_PARAM = "rubrik";
const CMS_VIDEO_PARAM = "id";

function App() {
  useIframeHeight();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState<Category[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [plenaryCategory, setPlenaryCategory] = useState<Category | undefined>();
  const checkRedirect = useRef(true);

  useEffect(() => {
    window.parent.postMessage("navigate", "*");
  }, [window.location.pathname]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      if (loaded) {
        return;
      }
      const categories = await getCategories();
      const plenaryCategory = categories.find((c) => c.name === CategoryType.Plenarsitzungen);
      setPlenaryCategory(plenaryCategory);
      setCategories(categories);
      setLoaded(true);
    };
    const cmsCategory = searchParams.get(CMS_CATEGORY_PARAM);
    const cmsVideo = searchParams.get(CMS_VIDEO_PARAM);
    if (checkRedirect.current && (cmsCategory || cmsVideo)) {
      const redirect = async () => {
        checkRedirect.current = false;
        const url = await getLegacyRedirectUrl({ cmsCategory, cmsVideo });
        if (url) {
          navigate(url, { replace: true });
        } else {
          const params = filterCmsParams(searchParams);
          setSearchParams(params);
        }
      };
      redirect();
    } else if (!loaded) {
      fetchCategories();
    }
    checkRedirect.current = false;
  }, [searchParams, setSearchParams, navigate, loaded]);

  return (
    <div className="bg-gray-300">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage categories={categories} />} />
          <Route path="category/:categoryName" element={<CategoryPage categories={categories} />} />
          <Route path="plenary-meeting/:title" element={<PlenaryMeetingPage plenaryCategory={plenaryCategory} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

function filterCmsParams(params: URLSearchParams) {
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    if (key !== CMS_CATEGORY_PARAM && key !== CMS_VIDEO_PARAM) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, string>);
}
