import { MutableRefObject, createRef, useEffect, useMemo, useRef, useState } from "react";
import { Agenda, Download, PlenaryMeeting, Video } from "src/types";
import { entityDownload } from "./entity-download";

const POPUP_POSITION_STYLES_RESPONSIVE =
  "top-[calc(100%_-_1rem)] right-1/2 translate-x-1/2 -translate-y-full md:top-12 md:right-2 md:translate-x-0 md:translate-y-0 max-w-[calc(100%_-_1rem)]";

const POPUP_POSITION_STYLES = "top-12 right-2 max-w-[calc(100%_-_1rem)] z-50";

export function useDownload(meeting: PlenaryMeeting | Video | null | undefined, responsive = true) {
  const [meetingDownload, setMeetingDownload] = useState<Download>();
  const [agendaItemDownload, setAgendaItemDownload] = useState<Download>();
  const [speechesDownload, setSpeechesDownload] = useState<Download[]>();
  const [otherVideoDownload, setOtherVideoDownload] = useState<Download>();
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const downloadBtnRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const desktopDownloadBtnRef = createRef<HTMLButtonElement>();
  const mobileDownloadBtnRef = createRef<HTMLButtonElement>();
  // const desktopDownloadBtnRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;
  // const mobileDownloadBtnRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;

  // const downloads = useMemo(() => {
  //   const availableDownloads = [];
  //   if (meetingDownload) {
  //     availableDownloads.push(meetingDownload);
  //   }
  //   if (agendaItemDownload) {
  //     availableDownloads.push(agendaItemDownload);
  //   }
  //   if (speechesDownload?.length) {
  //     availableDownloads.push(...speechesDownload);
  //   }
  //   if (otherVideoDownload) {
  //     availableDownloads.push(otherVideoDownload);
  //   }
  //   return availableDownloads;
  // }, [meetingDownload, agendaItemDownload, speechesDownload, otherVideoDownload]);

  useEffect(() => {
    setMeetingDownload(entityDownload(meeting));
  }, [meeting]);

  // useEffect(() => {
  //   if (!showDownloadButton && downloads.length) {
  //     setShowDownloadButton(true);
  //   } else if (showDownloadButton && !downloads.length) {
  //     setShowDownloadButton(false);
  //   }
  // }, [showDownloadButton, downloads.length]);

  useEffect(() => {
    setShowDownloadButton(!!meetingDownload || !!agendaItemDownload || !!speechesDownload || !!otherVideoDownload);
  }, [meetingDownload, agendaItemDownload, speechesDownload, otherVideoDownload]);

  useEffect(() => {
    downloadBtnRefs.current = [desktopDownloadBtnRef.current, mobileDownloadBtnRef.current];
  }, [desktopDownloadBtnRef, mobileDownloadBtnRef]);

  const toggleDownloadPopup = () => {
    setShowDownloadPopup((show) => !show);
  };

  const setDownloads = ({ agenda, video }: { agenda?: Agenda; video?: Video }) => {
    setShowDownloadPopup(false);
    setAgendaItemDownload(entityDownload(agenda));
    setSpeechesDownload(getSpeechesDownload(agenda));
    setOtherVideoDownload(entityDownload(video));
  };

  if (!responsive) {
    return {
      meetingDownload,
      agendaItemDownload,
      speechesDownload,
      otherVideoDownload,
      showDownloadButton,
      showDownloadPopup,
      downloadBtnRef: desktopDownloadBtnRef,
      toggleDownloadPopup,
      setShowDownloadPopup,
      setDownloads,
      downloadPosition: POPUP_POSITION_STYLES,
    };
  }

  return {
    // downloads,
    meetingDownload,
    agendaItemDownload,
    speechesDownload,
    otherVideoDownload,
    showDownloadButton,
    showDownloadPopup,
    downloadBtnRefs,
    desktopDownloadBtnRef,
    mobileDownloadBtnRef,
    toggleDownloadPopup,
    setShowDownloadPopup,
    setDownloads,
    downloadPosition: POPUP_POSITION_STYLES_RESPONSIVE,
    // downloadPosition: responsive ? POPUP_POSITION_STYLES_RESPONSIVE : POPUP_POSITION_STYLES,
  };
}

function getSpeechesDownload(agenda?: Agenda) {
  if (!agenda) {
    return;
  }
  const speeches = agenda.field_speeches.reduce((acc, curr) => {
    const download = entityDownload(curr);
    if (download) {
      acc.push(download);
    }
    return acc;
  }, [] as Download[]);
  return speeches.length ? speeches : undefined;
}
