import { ActiveContainer } from "../../active-container";
import { Image } from "../../image";
import { PlayIcon } from "../..//play-icon";
import { useContext, useEffect, useRef, useState } from "react";
import { CaretRight } from "./caret-right";
import { FederalState, Speech as SpeechType } from "../../../types";
import { AgendasContext } from "../context/agendas.context";

export function Speech({
  speech,
  minHeight,
  live,
  containerDisabled,
  onSpeechClick,
  onInit,
}: {
  speech: SpeechType;
  minHeight?: number;
  live: boolean;
  containerDisabled: boolean;
  onSpeechClick: (speech: SpeechType) => void;
  onInit: (height: number) => void;
}) {
  const [avatarHovered, setAvatarHovered] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  const { activeSpeech } = useContext(AgendasContext);

  useEffect(() => {
    const height = titleRef.current?.getBoundingClientRect().height;
    if (height !== undefined) {
      onInit(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speech]);

  return (
    <div
      className="flex flex-col w-[100px]"
      onClick={(evt) => evt.stopPropagation()}
    >
      {/* TEMP */}
      <ActiveContainer onChange={setAvatarHovered}>
        <button
          className="w-20 h-20 focus:outline-none"
          disabled={
            containerDisabled ||
            !speech.field_kmc_id ||
            speech.id === activeSpeech?.id
          }
          onClick={(evt) => {
            evt.stopPropagation();
            onSpeechClick(speech);
          }}
        >
          <div className="relative w-16 h-16 rounded-[50%] mx-auto">
            <Image
              className="w-16 h-16 overflow-hidden rounded-[50%]"
              src={speech.field_speaker_profile_image_link?.uri}
              alt={`Profilbild von ${speech.field_speaker_name}`}
            />
            <div
              className={`absolute w-full h-full left-0 top-0 flex items-center justify-center rounded-[50%]
                            text-white bg-black/50 transition-opacity duration-500 opacity-${
                              avatarHovered &&
                              !containerDisabled &&
                              speech.field_kmc_id &&
                              !(speech.id === activeSpeech?.id)
                                ? 100
                                : 0
                            } z-10`}
            >
              REDE
            </div>
            {!containerDisabled && speech.field_kmc_id && (
              <PlayIcon
                className="absolute right-[-12px] top-0"
                widthAndHeightClass="w-3.5 h-3.5"
                iconSize={7}
                fillColorClass={live ? "fill-white" : "fill-red-400"}
                borderColorClass={live ? "border-white" : "border-red-400"}
                borderWidthClass="border"
              />
            )}
          </div>
        </button>
      </ActiveContainer>
      <div className="flex" style={{ minHeight }} ref={titleRef}>
        {speech.field_speaker_profile_link?.uri ? (
          <a
            className={`flex max-w-full text-2xs ${
              live ? "!text-white" : "!text-red-400"
            } hover:underline focus:underline focus:outline-none overflow-hidden text-ellipsis`}
            href={speech.field_speaker_profile_link.uri}
            target="_blank"
            rel="noreferrer"
            onClick={(evt) => evt.stopPropagation()}
          >
            <SpeakerName
              name={speech.field_speaker_name}
              title={speech.field_speaker_title}
              live={live}
            />
          </a>
        ) : speech.field_speaker_name ? (
          <span
            className={`flex max-w-full text-2xs ${
              live ? "text-white" : "text-red-400"
            } overflow-hidden text-ellipsis`}
          >
            <SpeakerName
              name={speech.field_speaker_name}
              title={speech.field_speaker_title}
              live={live}
            />
          </span>
        ) : undefined}
      </div>

      <AdditionalInfo
        fedstate={speech.field_fedstate}
        department={speech.field_department}
        live={live}
      />
    </div>
  );
}

function SpeakerName({
  name,
  title,
  live,
}: {
  name?: string | null;
  title?: string | null;
  live: boolean;
}) {
  return (
    <>
      <CaretRight className="shrink-0 mt-1 mr-1" />
      {title ? title + " " : ""}
      {name ? name : ""}
    </>
  );
}

interface AdditionalInfoProps {
  fedstate: FederalState | null;
  department: string | null;
  live: boolean;
}

function AdditionalInfo({ fedstate, department, live }: AdditionalInfoProps) {
  const [info, setInfo] = useState("");

  useEffect(() => {
    setInfo(fedstate?.name || department || "");
  }, [fedstate, department]);

  if (!info) {
    return null;
  }
  return (
    <p
      className={`max-w-full text-2xs px-2 mt-1 border-t border-dotted ${
        live ? "border-white text-white" : "border-gray-500"
      } overflow-hidden text-ellipsis`}
    >
      {info}
    </p>
  );
}
