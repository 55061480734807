import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { upateStarttime } from "src/lib/utils/format-date";
import { MeetingStatus } from "src/models/meeting-status.model";
import { PlenaryMeeting } from "../types";
import { ActiveContainer } from "./active-container";
import { Countdown } from "./countdown";

export function LivestreamPreview({
  meeting,
  to,
}: {
  meeting: PlenaryMeeting;
  to?: string;
}) {
  const [active, setActive] = useState(false);
  const [date, setDate] = useState<Date>();
  const textContainerClass =
    "flex flex-col h-min text-white bg-black/40 focus:outline-none mt-auto mb-3 mx-3 p-4 z-10 pointer-events-none";

  useEffect(() => {
    const meetingDate = meeting.field_plenary_meeting_date;
    const meetingStarttime = meeting.field_plenary_meeting_starttime
      ? meeting.field_plenary_meeting_starttime
      : 34200; // Number of seconds from midnight (default: 09:30 AM)
    if (meetingDate) {
      const date = new Date(upateStarttime(meetingDate, meetingStarttime));
      setDate(date);
    }
  }, [meeting]);

  return (
    <ActiveContainer
      className="relative w-[240px] h-[430px] flex justify-center overflow-hidden"
      to={to}
      onChange={setActive}
    >
      {to ? (
        <Link to={to} className={textContainerClass}>
          <LivestreamText
            title={meeting.title}
            date={date}
            active={active}
            live={meeting.field_meeting_status === MeetingStatus.Live}
          />
        </Link>
      ) : (
        <div className={textContainerClass}>
          <LivestreamText
            title={meeting.title}
            date={date}
            live={meeting.field_meeting_status === MeetingStatus.Live}
          />
        </div>
      )}
      <div
        className="absolute w-full h-full left-0 top-0 bg-livestream bg-cover [background-position-x:82.5%]"
        aria-hidden
      />
    </ActiveContainer>
  );
}

const LivestreamText = ({
  title,
  date,
  active,
  live,
}: {
  title: string;
  date: Date | undefined;
  active?: boolean;
  live: boolean;
}) => {
  return (
    <>
      <span className="font-bold text-[35px] text-center py-1 mb-3 border-t-4 border-b">
        Livestream
      </span>

      <span className={`leading-5 mb-1 ${active ? "underline" : ""}`}>
        {title}
      </span>
      {live ? (
        <span className={`flex items-center`}>
          <span className="relative block w-2.5 h-2.5 mr-2 bg-red-100 rounded-[50%] before:absolute before:left-0 before:top-0 before:block before:w-2.5 before:h-2.5 before:bg-red-100 before:rounded-[50%] before:animate-ping"></span>
          Live
        </span>
      ) : (
        date &&
        !live && (
          <Countdown className={`${active ? "underline" : ""}`} date={date} />
        )
      )}
    </>
  );
};
