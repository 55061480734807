import { ReactNode, Children, useRef, useEffect, useState } from "react";
import SwiperRef from "swiper";
import { Controller, Pagination, Scrollbar } from "swiper/modules"
import { Swiper as LibSwiper, SwiperSlide } from "swiper/react";
import { ReactComponent as Caret } from "../assets/caret-right.svg";

import "swiper/css/pagination";
import { SwiperModule } from "swiper/types/shared";

export function Swiper({
  children,
  className,
  hero,
  scrollbar,
  activeSlide,
  navigation,
  position,
  positionItemLabel,
  previewItems,
  overlay,
  slidesPerView,
  onSlideChange
}: {
  children: ReactNode;
  className?: string;
  hero?: boolean;
  scrollbar?: boolean;
  activeSlide?: number;
  navigation?: boolean;
  position?: boolean;
  positionItemLabel?: string;
  previewItems?: JSX.Element[];
  overlay?: JSX.Element,
  slidesPerView?: number,
  onSlideChange?: (index?: number) => void,
}) {
  const [keyboardFocus, setKeyboardFocus] = useState(false);
  const swiperRef = useRef<SwiperRef>();
  const swiperLeftRef = useRef<SwiperRef>();
  const swiperRightRef = useRef<SwiperRef>();
  const buttonClassShared = "absolute top-1/2 translate-y-[-50%]";

  useEffect(() => {
    Children.count(children) >= 4
      ? swiperRef.current?.wrapperEl.classList.add("justify-between")
      : swiperRef.current?.wrapperEl.classList.remove("justify-between");
  }, [children]);

  useEffect(() => {
    window.addEventListener("keydown", keydownListener);
    window.addEventListener("mousedown", mousedownListener);

    return () => {
      window.removeEventListener("keydown", keydownListener);
      window.removeEventListener("mousedown", mousedownListener);
    };
  }, [hero]);

  useEffect(() => {
    if (activeSlide !== undefined) {
      swiperRef.current?.slideTo(activeSlide);
    }
  }, [activeSlide]);

  const generateModules = () => {
    const modules: SwiperModule[] = [];
    if (scrollbar) {
      modules.push(Scrollbar);
    }
    if (hero) {
      modules.push(Pagination, Controller);
    }

    return modules;
  };

  const keydownListener = (evt: KeyboardEvent) => {
    if (evt.key === "Tab") {
      setKeyboardFocus(true);
    }
  };

  const mousedownListener = (evt: MouseEvent) => {
    setKeyboardFocus(false);
  };

  return (
    <div
      className={`${hero ? "relative flex" : ""} ${className ? className : ""}`}
    >
      {hero && !!previewItems?.length && (
        <AuxiliarySwiper
          position="left"
          elements={previewItems || []}
          onSwiper={(swiper) => (swiperLeftRef.current = swiper)}
        />
      )}
      <LibSwiper
        className={hero && previewItems?.length ? "relative sm:w-full lg:w-3/5" : "relative"}
        grabCursor
        loop={hero}
        speed={hero ? 750 : 500}
        slidesPerView={slidesPerView || (hero ? 1 : "auto")}
        spaceBetween={hero ? 0 : 20}
        initialSlide={0}
        modules={generateModules()}
        scrollbar={
          scrollbar && {
            draggable: true,
            dragSize: "auto",
          }
        }
        pagination={hero && { clickable: true }}
        controller={
          hero && swiperLeftRef.current && swiperRightRef.current
            ? {
                control: [swiperLeftRef.current, swiperRightRef.current],
              }
            : undefined
        }
        onSwiper={(swiper: SwiperRef) => (swiperRef.current = swiper)}
        onSlideChange={() => onSlideChange?.(swiperRef.current?.realIndex)}
      >
        {Children.map(children, (child, i) => {
          return (
            <SwiperSlide
              onFocus={() => {
                if (!keyboardFocus) {
                  return;
                }

                if (hero) {
                  swiperRef.current?.el.scrollTo(0, 0);
                  swiperRef.current?.slideTo(i + 1);
                } else {
                  swiperRef.current?.slideTo(i);
                }
              }}
            >
              {child}
            </SwiperSlide>
          );
        })}
        { overlay }
      </LibSwiper>
      {hero && !!previewItems?.length && (
        <AuxiliarySwiper
          position="right"
          elements={previewItems}
          onSwiper={(swiper) => (swiperRightRef.current = swiper)}
        />
      )}
      {hero && (
        <>
          <NavButton
            back
            hero
            className={`left-0 ${buttonClassShared}`}
            onClick={() => swiperRef.current?.slidePrev()}
          ></NavButton>
          <NavButton
            hero
            className={`right-0 ${buttonClassShared}`}
            onClick={() => swiperRef.current?.slideNext()}
          ></NavButton>
        </>
      )}
      {navigation && (
        <div className="flex justify-end items-center mt-5 pt-5 border-t-4 border-gray-400">
          {position && (
            <PositionDisplay
              activeIndex={swiperRef.current?.activeIndex}
              total={swiperRef.current?.slides.length}
              label={positionItemLabel}
            />
          )}
          <NavButton
            back
            disabled={swiperRef.current?.isBeginning}
            className="mr-2"
            onClick={() => swiperRef.current?.slidePrev()}
          ></NavButton>
          <NavButton
            disabled={swiperRef.current?.isEnd}
            onClick={() => swiperRef.current?.slideNext()}
          ></NavButton>
        </div>
      )}
    </div>
  );
}

function AuxiliarySwiper({
  position,
  elements,
  onSwiper,
}: {
  position: "left" | "right";
  elements: JSX.Element[];
  onSwiper: (swiper: SwiperRef) => void;
}) {
  const [slideElts, setSlideElts] = useState<JSX.Element[]>([]);
  const key = Math.random().toString(32);

  useEffect(() => {
    if (position === "left") {
      const lastIndex = elements.length - 1;
      const lastElt = elements[lastIndex];
      setSlideElts([lastElt, ...elements.slice(0, lastIndex)]);
      return;
    }

    setSlideElts([...elements.slice(1), elements[0]]);
  }, [elements, position]);

  return (
    <LibSwiper
      className={`hidden lg:block relative w-1/5 ${
        position === "left" ? "mr-[-1px]" : "ml-[-1px]"
      }`}
      loop
      slidesPerView={1}
      modules={[Controller]}
      onSwiper={onSwiper}
    >
      {slideElts.map((elt, i) => (
        <SwiperSlide key={`aux_swiper_slide_${key}_${i}`}>{elt}</SwiperSlide>
      ))}
      <div className="absolute left-0 top-0 w-full h-full bg-black/50" />
    </LibSwiper>
  );
}

interface PositionDisplayProps {
  activeIndex?: number;
  total?: number;
  label?: string
}

function PositionDisplay({ activeIndex = 0, total = 0, label}: PositionDisplayProps) {
 return <span className="mr-4">{ total ? activeIndex + 1 : 0 }/{total} { label }</span>
}

function NavButton({
  back,
  className = '',
  disabled,
  hero,
  onClick,
}: {
  back?: boolean;
  className?: string;
  disabled?: boolean;
  hero?: boolean;
  onClick: () => void;
}) {
  return (
    <div className={`w-10 h-10 ${className}`}>
      <button
        className={`relative flex items-center justify-center w-full h-full p-1 ${!hero ? 'bg-gray-500 disabled:bg-gray-400' : ''}`}
        aria-label={back ? "Vorheriges Dia" : "Nächstes Dia"}
        disabled={disabled}
        onClick={onClick}
      >
        {hero && <div className="absolute left-0 top-0 w-full h-full bg-white/40" />}
        <Caret
          className={`stroke-white ${back ? "rotate-180 translate-y-0 mr-0.5" : "ml-0.5"}`}
        />
      </button>
    </div>
  );
}
