import { Video } from "src/types";

const openerTitlePrefix = "Sitzungseröffnung";

export function OtherVideos({
  videos,
  activeVideo,
  openerLive,
  borderClassname,
  onClick,
}: {
  videos: Video[];
  activeVideo: Video | undefined;
  openerLive: boolean;
  borderClassname: string;
  onClick: (video: Video) => void;
}) {
  return (
    <div
      className={`${borderClassname} ${
        videos.length % 2 ? "border-gray-100" : "border-gray-400"
      }`}
    >
      {videos.map((video, i) => (
        <VideoButton
          key={`video_button_${video.id}`}
          video={video}
          activeVideo={activeVideo}
          introLive={openerLive}
          className={
            i % 2
              ? "bg-gray-400/50"
              : "bg-black/0"
          }
          onClick={() => onClick(video)}
        />
      ))}
    </div>
  );
}

const VideoButton = ({
  video,
  activeVideo,
  introLive,
  className = "",
  onClick,
}: {
  video: Video;
  activeVideo?: Video | undefined;
  introLive?: boolean;
  className?: string;
  onClick: () => void;
}) => {
  const sharedClass = "max-w-full overflow-hidden text-ellipsis";

  return (
    <button
      key={`other_video_item_${video.id}`}
      className={`w-full pl-[18px] pr-3 py-2 text-left ${className} ${
        introLive && video.title.startsWith(openerTitlePrefix) ? "!bg-red-900 !text-white" : ""
      } ${video.id === activeVideo?.id && !introLive ? "!bg-white" : ""}  ${
        video.id !== activeVideo?.id && video.field_kmc_id ? "md:hover:bg-white hover:underline focus:underline" : ""
      } focus:outline-none`}
      disabled={!video.field_kmc_id || video.id === activeVideo?.id}
      onClick={onClick}
    >
      <h3 className={`font-semibold text-lg ${sharedClass}`}>{video.title}</h3>
    </button>
  );
};
