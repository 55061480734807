import { PrimaryButton } from "../../button";
import { TimesIcon } from "../../times-icon";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { FormEvent, useRef, useState } from "react";

export function AgendaItemForm({
  selectedAgendaItem,
  activeAgendaItem,
  setSelectedAgendaItem,
  handleSubmit,
}: {
  selectedAgendaItem: string;
  activeAgendaItem: string | null | undefined;
  setSelectedAgendaItem: (number: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>, select?: boolean) => void;
}) {
  const [active, setActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const sharedClasses =
    "text-xs font-semibold px-3 py-0.5 border-b border-gray-400/50";

  const submitAgendaItem = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    inputRef.current?.blur();

    handleSubmit(evt, true);
  };

  return (
    <form
      className="flex items-center md:hidden mb-0"
      onSubmit={submitAgendaItem}
      onFocusCapture={() => setActive(true)}
      onBlurCapture={() => setActive(false)}
    >
      <div className="relative">
        <input
          className={`relative max-w-[100px] text-center bg-white/0 outline-none ${sharedClasses} ${
            active ? "z-10" : "opacity-0 -z-10"
          }`}
          value={selectedAgendaItem}
          aria-label="Gehe zu TOP nr."
          onChange={(evt) => setSelectedAgendaItem(evt.target.value)}
          onFocus={() =>
            setSelectedAgendaItem(activeAgendaItem ? activeAgendaItem : "")
          }
          ref={inputRef}
        />
        <div
          className={`absolute left-0 top-0 w-full h-full ${sharedClasses} ${
            active ? "opacity-0 -z-10" : "z-10"
          }`}
          onClick={() => {
            setActive(true);
            inputRef.current?.focus();
          }}
        >
          {!active && activeAgendaItem ? `TOP ${activeAgendaItem}` : ""}
        </div>
      </div>
      <button
        type="reset"
        aria-label="Reset"
        className={`p-0.5 ${
          selectedAgendaItem && active ? "visible" : "invisible"
        }`}
        onClick={() => setSelectedAgendaItem("")}
      >
        <TimesIcon className="fill-white" />
      </button>
      <PrimaryButton
        type="submit"
        ariaLabel="Senden"
        disabled={!active && !selectedAgendaItem}
        className={`h-auto ml-2 ${active ? "" : "opacity-20"}`}
      >
        <Search />
      </PrimaryButton>
    </form>
  );
}
