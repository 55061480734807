import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { ActiveEntry, Agenda, CurrentAgendaItem, PlenaryMeeting, Speech, Video } from "src/types";
import { Agendas } from "src/components/agenda/agendas";
import { OutsideLink } from "src/components/outside-link";
import { Spinner } from "src/components/spinner";
import { KalturaPlayer, KalturaPlayerProvider, PlayerBundleConfig, usePlayerConfig } from "src/lib/kaltura-player";
import axios from "axios";
import { getLivePlenaryMeeting } from "src/api/plenary-meetings.api";
import { ShareButton } from "src/components/share-button";
import { PlenaryShare } from "src/components/plenary-share";
import { getAgendas } from "src/api/agenda.api";
import { AgendaActions, agendaReducer, initialAgendaState } from "src/embed/reducer/agenda-reducer";
import { UpcomingMeeting } from "src/components/upcoming-meeting";
import { MeetingStatus } from "src/models/meeting-status.model";
import { DownloadButton } from "src/components/download-button";
// import { PlenaryDownload } from "src/components/plenary-download/plenary-download";
import { PlenaryDownload } from "src/components/plenary-download";
import { useDownload } from "src/lib/utils/use-download";
import { useShare } from "src/lib/utils/use-share";
import { isOpeningVideo } from "src/lib/utils/is-opening-video";
import { useIframeHeight } from "src/lib/utils/use-iframe-height";

const bundlerConfig: Partial<PlayerBundleConfig> = {
  bundlerUrl: process.env.REACT_APP_KALTURA_BASE_URL,
  partnerId: process.env.REACT_APP_KALTURA_PARTNER_ID,
  ks: "",
  uiConfId: process.env.REACT_APP_KALTURA_PLAYER_UICONF_ID,
};

const LIVESTREAM_ID = process.env.REACT_APP_KALTURA_LIVESTREAM_ENTRY_ID;
const SIGN_LANGUAGE_ID = process.env.REACT_APP_KALTURA_LIVESTREAM_SIGN_LANGUAGE_ENTRY_ID;

const REACH_TIME = 15 * 60 * 1000;

function Livestream() {
  useIframeHeight();
  const [activeEntry, setActiveEntry] = useState<ActiveEntry>();
  const { entryId, customizeConfig } = usePlayerConfig(
    activeEntry ? activeEntry.field_kmc_id : LIVESTREAM_ID,
    activeEntry ? activeEntry.field_sign_kmc_id : SIGN_LANGUAGE_ID
  );
  const [meeting, setMeeting] = useState<PlenaryMeeting>();
  const [upcoming, setUpcoming] = useState(true);
  const [agendaState, agendaDispatch] = useReducer(agendaReducer, initialAgendaState);
  const [introLive, setIntroLive] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    // downloads,
    setDownloads,
    meetingDownload,
    agendaItemDownload,
    speechesDownload,
    otherVideoDownload,
    showDownloadButton,
    showDownloadPopup,
    toggleDownloadPopup,
    setShowDownloadPopup,
    downloadBtnRefs,
    desktopDownloadBtnRef,
    mobileDownloadBtnRef,
    downloadPosition,
  } = useDownload(meeting);
  // const downloadBtnRefs = useRef([desktopDownloadBtnRef, mobileDownloadBtnRef]);
  const { showSharePopup, setShowSharePopup, toggleSharePopup, shareBtnRefs, desktopShareBtnRef, mobileShareBtnRef, sharePosition } =
    useShare();
  // const shareBtnRefs = useRef([desktopShareBtnRef, mobileShareBtnRef]);

  useEffect(() => {
    getLivePlenaryMeeting()
      .then((meeting) => {
        setMeeting(meeting);
        agendaDispatch({
          type: AgendaActions.LoadAgendas,
          payload: meeting?.field_agenda,
        });
        if (meeting) {
          setUpcoming(meeting.field_meeting_status === MeetingStatus.Geplant);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (
        agendaState.previousAgendaItem &&
        agendaState.previousAgendaItem.agendaItemNumber !== agendaState.currentAgendaItem?.agendaItemNumber
      ) {
        const agendas = await getAgendas(agendaState.agendas.map((agenda) => agenda.id));
        agendaDispatch({ type: AgendaActions.LoadAgendas, payload: agendas });
      }
    })();
  }, [agendaState]);

  useEffect(() => {
    if (upcoming) {
      return;
    }

    getCurrentAgendaItem().then((res) => {
      if (
        (res?.status === 204 && meeting?.field_meeting_status === MeetingStatus.Live) ||
        (res?.data.liveMeeting && !res.data.agendaItemNumber)
      ) {
        const meetingOpener = meeting?.field_video_others.find((video) => isOpeningVideo(video));
        if (meetingOpener) {
          setIntroLive(true);
        }
      } else {
        setIntroLive(false);
      }
    });
    const updateInterval = setInterval(() => {
      getCurrentAgendaItem(true);
    }, 20000);
    return () => clearInterval(updateInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcoming]);

  const getCurrentAgendaItem = async (timeout?: boolean) => {
    try {
      const res = await axios.get<CurrentAgendaItem>(
        `${process.env.REACT_APP_SYNC_API_BASE_URL}/live/currentAgendaItem`
      );
      setTimeout(
        () => {
          agendaDispatch({
            type: AgendaActions.LoadCurrentItem,
            payload: res.data,
          });
          if (res?.data.agendaItemNumber) {
            setIntroLive(false);
          }
        },
        timeout ? 15 * 1000 : 0
      );
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const handleAgendaClick = (agenda: Agenda) => {
    if (agenda.field_item_number === agendaState.currentAgendaItem?.agendaItemNumber) {
      setDownloads({});
      return setActiveEntry(undefined);
    }
    setDownloads({ agenda });
    setActiveEntry(agenda);
    emitScrollMessage();
  };

  const handleSpeechClick = (speech: Speech, agenda: Agenda) => {
    setDownloads({ agenda });
    setActiveEntry(speech);
    emitScrollMessage();
  };

  const handleVideoClick = (video: Video) => {
    setDownloads({ video });
    setActiveEntry(video);
    emitScrollMessage();
  };

  const emitScrollMessage = () => {
    window.parent.postMessage("scrollIntoView", "*");
  };

  const countdownReached = useCallback(() => {
    setUpcoming(false);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return meeting ? (
    <div className="tsmms-embed-wrapper flex flex-col md:flex-row max-w-screen-xl mx-auto bg-gray-300">
      <div className="w-full md:w-1/2">
        {entryId && (
          <div className="relative w-full pb-[56.25%] md:mb-4">
            <div
              className={`absolute w-full h-full ${
                upcoming ? "flex items-center justify-center bg-livestream bg-cover" : ""
              }`}
            >
              {!upcoming ? (
                <KalturaPlayer className="w-full h-full" entryId={entryId} customizeConfig={customizeConfig} />
              ) : (
                <UpcomingMeeting
                  date={meeting.field_plenary_meeting_date}
                  time={meeting.field_plenary_meeting_starttime}
                  countdownReached={countdownReached}
                  reachTime={REACH_TIME}
                />
              )}
            </div>
            {showDownloadButton && (
              <>
                <DownloadButton
                  ref={desktopDownloadBtnRef}
                  overlayed
                  className="hidden md:flex absolute top-2 right-12 z-50"
                  handleClick={toggleDownloadPopup}
                />
                {showDownloadPopup && (
                  <PlenaryDownload
                    className={downloadPosition}
                    // anchors={downloadBtnRefs.current}
                    anchors={downloadBtnRefs?.current}
                    meetingDownload={meetingDownload}
                    agendaItemDownload={agendaItemDownload}
                    speechesDownload={speechesDownload}
                    otherVideoDownload={otherVideoDownload}
                    // downloads={downloads}
                    handleClickOutside={() => setShowDownloadPopup(false)}
                    handleCancel={() => setShowDownloadPopup(false)}
                  ></PlenaryDownload>
                )}
              </>
            )}
            <ShareButton
              ref={desktopShareBtnRef}
              overlayed
              className="hidden md:flex absolute top-2 right-2 z-50"
              handleClick={toggleSharePopup}
            />
            {showSharePopup && (
              <PlenaryShare
                className={sharePosition}
                // anchors={shareBtnRefs.current}
                anchors={shareBtnRefs?.current}
                entry={activeEntry ?? meeting}
                showEmbed={false}
                handleClickOutside={() => setShowSharePopup(false)}
                handleCancel={() => setShowSharePopup(false)}
              ></PlenaryShare>
            )}
          </div>
        )}
        <div className="hidden md:flex flex-col">
          <div className="flex items-center justify-between pb-1 border-b border-dotted cursor-default">
            <h2 className="text-xl border-none py-0">Mehr zur Sitzung</h2>
          </div>

          {meeting.field_complete_agenda?.uri && (
            <OutsideLink className="mt-8 text-xs" href={meeting.field_complete_agenda.uri}>
              Detaillierte Tagesordnung
            </OutsideLink>
          )}
          {meeting.field_blog_link?.uri && (
            <OutsideLink className="mt-4 text-xs" href={meeting.field_blog_link?.uri}>
              BundesratKOMPAKT
            </OutsideLink>
          )}
          {meeting.field_meeting_protocol_link?.uri && (
            <OutsideLink className="mt-4 text-xs" href={meeting.field_meeting_protocol_link?.uri}>
              Plenarprotokoll
            </OutsideLink>
          )}
        </div>
      </div>
      <div className="w-full md:w-1/2">
        {agendaState.agendas.length ? (
          <Agendas
            agendas={agendaState.agendas}
            otherVideosBeforeAgenda={meeting.field_video_others}
            otherVideosAfterAgenda={meeting.field_video_others_after}
            liveAgendaNumber={
              agendaState.currentAgendaItem?.liveMeeting ? agendaState.currentAgendaItem?.agendaItemNumber : undefined
            }
            introLive={introLive}
            videoActions={
              <div className="flex">
                {showDownloadButton && (
                  <DownloadButton className="mr-2" ref={mobileDownloadBtnRef} handleClick={toggleDownloadPopup} />
                )}
                <ShareButton ref={mobileShareBtnRef} handleClick={toggleSharePopup} />
              </div>
            }
            disableActions={!agendaState.currentAgendaItem?.agendaItemNumber}
            onAgendaClick={handleAgendaClick}
            onSpeechClick={handleSpeechClick}
            onVideoClick={handleVideoClick}
          />
        ) : undefined}
      </div>
    </div>
  ) : null;
}

export default function PlenaryLivestreamEmbed() {
  return (
    <KalturaPlayerProvider playerBundleConfig={bundlerConfig}>
      <Livestream />
    </KalturaPlayerProvider>
  );
}
