export function TimesIcon({ className }: { className?: string }) {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="9px"
      height="9px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 -156 -25 )">
        <path
          className={className ? className : ""}
          d="M 8.787878787878787 6.727272727272727  C 8.929292929292929 6.868686868686868  9 7.04040404040404  9 7.242424242424242  C 9 7.4444444444444455  8.929292929292929 7.616161616161616  8.787878787878787 7.757575757575757  L 7.757575757575757 8.787878787878787  C 7.616161616161618 8.929292929292929  7.444444444444445 9  7.242424242424242 9  C 7.0404040404040416 9  6.868686868686869 8.929292929292929  6.7272727272727275 8.787878787878787  L 4.5 6.560606060606061  L 2.272727272727273 8.787878787878787  C 2.1313131313131315 8.929292929292929  1.9595959595959598 9  1.7575757575757576 9  C 1.5555555555555558 9  1.383838383838384 8.929292929292929  1.2424242424242424 8.787878787878787  L 0.21212121212121213 7.757575757575757  C 0.07070707070707077 7.616161616161616  0 7.4444444444444455  0 7.242424242424242  C 0 7.04040404040404  0.07070707070707077 6.868686868686868  0.21212121212121213 6.727272727272727  L 2.4393939393939394 4.5  L 0.21212121212121213 2.2727272727272725  C 0.07070707070707077 2.1313131313131306  0 1.9595959595959598  0 1.7575757575757576  C 0 1.5555555555555554  0.07070707070707077 1.3838383838383825  0.21212121212121213 1.2424242424242427  L 1.2424242424242424 0.21212121212121215  C 1.383838383838384 0.07070707070707039  1.5555555555555558 0  1.7575757575757576 0  C 1.9595959595959598 0  2.1313131313131315 0.07070707070707039  2.272727272727273 0.21212121212121215  L 4.5 2.43939393939394  L 6.7272727272727275 0.21212121212121215  C 6.868686868686869 0.07070707070707039  7.0404040404040416 0  7.242424242424242 0  C 7.444444444444445 0  7.616161616161618 0.07070707070707039  7.757575757575757 0.21212121212121215  L 8.787878787878787 1.2424242424242427  C 8.929292929292929 1.3838383838383825  9 1.5555555555555554  9 1.7575757575757576  C 9 1.9595959595959598  8.929292929292929 2.1313131313131306  8.787878787878787 2.2727272727272725  L 6.5606060606060606 4.5  L 8.787878787878787 6.727272727272727  Z "
          fillRule="nonzero"
          fill="#797979"
          stroke="none"
          transform="matrix(1 0 0 1 156 25 )"
        />
      </g>
    </svg>
  );
}
