import { PrimaryButton } from "src/components/button";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from "react";
import { debounce } from "src/lib/utils/debounce";
import { scrollToElementWithOffset } from "src/lib/utils/scroll-to-with-offset";

const scrollMargin = 30;

export interface ContainerHandle {
  scrollToElement: (
    elt: HTMLElement | null | undefined,
    margin?: number
  ) => void;
}

export const ScrollContainer = forwardRef(
  (
    {
      children,
      activeElement,
      activeAgendaNumber,
      liveElement,
    }: {
      children: ReactNode;
      activeElement: HTMLDivElement | undefined;
      activeAgendaNumber?: string | null;
      liveElement: HTMLDivElement | undefined;
    },
    ref: ForwardedRef<ContainerHandle>
  ) => {
    const [showScrollToActive, setShowScrollToActive] = useState(false);
    const [showScrollToLive, setShowScrollToLive] = useState(false);
    const containerRef = useRef<HTMLUListElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      scrollToElement: handleScrollToElement,
    }));

    useEffect(() => {
      checkIntersections();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeElement, liveElement]);

    const itemsVisible = () => {
      return !!showScrollToActive || !!showScrollToLive;
    };

    const handleScrollToElement = (
      elt: HTMLElement | null | undefined,
      margin = 0
    ) => {
      scrollToElementWithOffset(containerRef.current, elt, margin);
    };

    const checkIntersections = debounce(() => {
      activeElement
        ? setShowScrollToActive(intersectionCallback(activeElement))
        : setShowScrollToActive(false);
      liveElement
        ? setShowScrollToLive(intersectionCallback(liveElement))
        : setShowScrollToLive(false);
    }, 100);

    const intersectionCallback = (child: HTMLDivElement) => {
      const parentRect = containerRef.current?.getBoundingClientRect();
      const childRect = child.getBoundingClientRect();
      if (!parentRect) {
        return false;
      }
      return (
        childRect.bottom - scrollMargin < parentRect.top ||
        childRect.top + scrollMargin > parentRect.bottom
      );
    };

    return (
      <>
        <div
          className={`hidden md:flex w-full transition-all duration-300 overflow-hidden ${
            showScrollToLive ? "bg-red-400" : "bg-gray-500"
          } ${!itemsVisible() ? "pointer-events-none" : ""}`}
          style={{
            opacity: itemsVisible() ? 100 : 0,
            height: itemsVisible() ? scrollMargin : 0,
          }}
          aria-hidden={!itemsVisible}
          ref={scrollContainerRef}
        >
          <PrimaryButton
            className={`w-full text-xs px-2 py-1 bg-gray-500  transition-opacity duration-300 ${
              showScrollToActive ? "opacity-100" : "opacity-0"
            }`}
            ariaLabel="Zurück zum ausgewählten TOP"
            disabled={!activeElement}
            onClick={() => handleScrollToElement(activeElement)}
          >
            Aufzeichnung TOP {activeAgendaNumber}
            ...
          </PrimaryButton>
          <PrimaryButton
            className={`shrink-0 ml-auto text-white text-xs px-2 py-1 ${
              !showScrollToLive ? "shrink-1 w-0 h-0 !p-0 overflow-hidden" : ""
            }`}
            disabled={!liveElement}
            onClick={() => handleScrollToElement(liveElement)}
          >
            zurück zum LIVE TOP
          </PrimaryButton>
        </div>
        <ul
          className={`max-h-[500px] ${
            itemsVisible() ? "md:max-h-[520px]" : "md:max-h-[550px]"
          } transition-all duration-300 overflow-x-hidden overflow-y-auto border-b-4 border-gray-100`}
          onScroll={checkIntersections}
          ref={containerRef}
        >
          {children}
        </ul>
      </>
    );
  }
);
