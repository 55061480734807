import axios from "axios";
import { drupal } from "src/lib/drupal";
import { getParams } from "src/lib/get-params";
import { LivestreamResponse, PlenaryMeeting } from "src/types";
import { getAgendas } from "./agenda.api";

export async function getPlenaryMeeting(title: string) {
  const meeting = await drupal.getResourceByPath<PlenaryMeeting>(
    `/plenary-meeting/${title}`,
    {
      params: getParams("node--plenary_meeting", {
        includes: ["field_video_others", "field_video_others_after"],
      }),
    }
  );

  return withAgendas(meeting);
}

export async function getPlenaryMeetingByCms(cmsCategory: string) {
  const [meeting] = await drupal.getResourceCollection<PlenaryMeeting[]>(
    `node--plenary_meeting`,
    {
      params: getParams("node--plenary_meeting", {
        cmsCategory
      }),
    }
  );

  return meeting;
}

export async function getLivePlenaryMeeting() {
  const liveRes = await axios.get<LivestreamResponse>(
    `${process.env.REACT_APP_SYNC_API_BASE_URL}/live/drupalnode`
  );

  if (liveRes.status === 204) {
    return undefined;
  }

  const meeting = await drupal.getResource<PlenaryMeeting>(
    "node--plenary_meeting",
    liveRes.data.value,
    {
      params: getParams("node--plenary_meeting", {
        includes: ["field_video_others", "field_video_others_after"],
      }),
    }
  );

  return withAgendas(meeting);
}

const withAgendas = async (meeting: PlenaryMeeting) => {
  const agendas = await getAgendas(
    meeting.field_agenda.map((agenda) => agenda.id)
  );
  meeting.field_agenda = agendas;
  return meeting;
};
