import { Jsona } from "jsona"
import { stringify } from "qs"
import { JsonApiErrors } from "./errors"
import { logger as defaultLogger } from "./logger"
import type {
  GetStaticPropsContext,
} from "next"
import type {
  AccessToken,
  BaseUrl,
  DrupalClientAuthAccessToken,
  DrupalClientAuthClientIdSecret,
  DrupalClientAuthUsernamePassword,
  DrupalClientOptions,
  JsonApiParams,
  JsonApiResource,
  JsonApiResponse,
  JsonApiWithAuthOptions,
  JsonApiWithCacheOptions,
  JsonApiWithLocaleOptions,
  Locale,
  PathPrefix,
} from "./types"

const DEFAULT_API_PREFIX = "/jsonapi"
const DEFAULT_FRONT_PAGE = "/home"
const DEFAULT_WITH_AUTH = false

// From simple_oauth.
const DEFAULT_AUTH_URL = "/oauth/token"

// See https://jsonapi.org/format/#content-negotiation.
const DEFAULT_HEADERS = {
  "Content-Type": "application/vnd.api+json",
  Accept: "application/vnd.api+json",
}

function isBasicAuth(
  auth: DrupalClientOptions["auth"]
): auth is DrupalClientAuthUsernamePassword {
  return (
    (auth as DrupalClientAuthUsernamePassword)?.username !== undefined ||
    (auth as DrupalClientAuthUsernamePassword)?.password !== undefined
  )
}

function isAccessTokenAuth(
  auth: DrupalClientOptions["auth"]
): auth is DrupalClientAuthAccessToken {
  return (auth as DrupalClientAuthAccessToken)?.access_token !== undefined
}

function isClientIdSecretAuth(
  auth: DrupalClient["auth"]
): auth is DrupalClientAuthClientIdSecret {
  return (
    (auth as DrupalClientAuthClientIdSecret)?.clientId !== undefined ||
    (auth as DrupalClientAuthClientIdSecret)?.clientSecret !== undefined
  )
}

export class DrupalClient {
  baseUrl: BaseUrl

  debug: DrupalClientOptions["debug"]

  frontPage: DrupalClientOptions["frontPage"]

  private serializer: DrupalClientOptions["serializer"]

  private cache: DrupalClientOptions["cache"] | null

  private throwJsonApiErrors?: DrupalClientOptions["throwJsonApiErrors"]

  private logger: DrupalClientOptions["logger"]

  private fetcher?: DrupalClientOptions["fetcher"]

  private _headers?: DrupalClientOptions["headers"]

  private _auth?: DrupalClientOptions["auth"]

  private useDefaultResourceTypeEntry?: DrupalClientOptions["useDefaultResourceTypeEntry"]

  private _token?: AccessToken

  private accessToken?: DrupalClientOptions["accessToken"]

  private accessTokenScope?: DrupalClientOptions["accessTokenScope"]

  private tokenExpiresOn?: number

  private withAuth: boolean

  /**
   * Instantiates a new DrupalClient.
   *
   * const client = new DrupalClient(baseUrl)
   *
   * @param {baseUrl} baseUrl The baseUrl of your Drupal site. Do not add the /jsonapi suffix.
   * @param {options} options Options for the client. See Experiment_DrupalClientOptions.
   */
  constructor(baseUrl: BaseUrl, options: DrupalClientOptions = {}) {
    if (!baseUrl || typeof baseUrl !== "string") {
      throw new Error("The 'baseUrl' param is required.")
    }

    const {
      serializer = new Jsona(),
      cache = null,
      debug = false,
      frontPage = DEFAULT_FRONT_PAGE,
      useDefaultResourceTypeEntry = false,
      headers = DEFAULT_HEADERS,
      logger = defaultLogger,
      withAuth = DEFAULT_WITH_AUTH,
      fetcher,
      auth,
      accessToken,
      throwJsonApiErrors = true,
    } = options

    this.baseUrl = baseUrl
    this.serializer = serializer
    this.frontPage = frontPage
    this.debug = debug
    this.useDefaultResourceTypeEntry = useDefaultResourceTypeEntry
    this.fetcher = fetcher
    this.auth = auth
    this.headers = headers
    this.logger = logger
    this.withAuth = withAuth
    this.cache = cache
    this.accessToken = accessToken
    this.throwJsonApiErrors = throwJsonApiErrors

    // Do not throw errors in production.
    if (process.env.NODE_ENV === "production") {
      this.throwJsonApiErrors = false
    }

    this._debug("Debug mode is on.")
  }

  set auth(auth: DrupalClientOptions["auth"]) {
    if (typeof auth === "object") {
      if (isBasicAuth(auth)) {
        if (!auth.username || !auth.password) {
          throw new Error(
            `'username' and 'password' are required for auth. See https://next-drupal.org/docs/client/auth`
          )
        }
      } else if (isAccessTokenAuth(auth)) {
        if (!auth.access_token || !auth.token_type) {
          throw new Error(
            `'access_token' and 'token_type' are required for auth. See https://next-drupal.org/docs/client/auth`
          )
        }
      } else if (!auth.clientId || !auth.clientSecret) {
        throw new Error(
          `'clientId' and 'clientSecret' are required for auth. See https://next-drupal.org/docs/client/auth`
        )
      }

      auth = {
        url: DEFAULT_AUTH_URL,
        ...auth,
      }
    }

    this._auth = auth
  }

  set headers(value: DrupalClientOptions["headers"]) {
    this._headers = value
  }

  private set token(token: AccessToken) {
    this._token = token
    this.tokenExpiresOn = Date.now() + token.expires_in * 1000
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async fetch(input: RequestInfo, init?: any): Promise<Response> {
    init = {
      ...init,
      credentials: "include",
      headers: {
        ...this._headers,
        ...init?.headers,
      },
    }

    // Using the auth set on the client.
    // TODO: Abstract this to a re-usable.
    if (init?.withAuth) {
      this._debug(`Using authenticated request.`)

      if (init.withAuth === true) {
        if (typeof this._auth === "undefined") {
          throw new Error(
            "auth is not configured. See https://next-drupal.org/docs/client/auth"
          )
        }

        // By default, if withAuth is set to true, we use the auth configured
        // in the client constructor.
        if (typeof this._auth === "function") {
          this._debug(`Using custom auth callback.`)

          init["headers"]["Authorization"] = this._auth()
        } else if (typeof this._auth === "string") {
          this._debug(`Using custom authorization header.`)

          init["headers"]["Authorization"] = this._auth
        } else if (typeof this._auth === "object") {
          this._debug(`Using custom auth credentials.`)

          if (isBasicAuth(this._auth)) {
            const basic = Buffer.from(
              `${this._auth.username}:${this._auth.password}`
            ).toString("base64")

            init["headers"]["Authorization"] = `Basic ${basic}`
          } else if (isClientIdSecretAuth(this._auth)) {
            // Use the built-in client_credentials grant.
            this._debug(`Using default auth (client_credentials).`)

            // Fetch an access token and add it to the request.
            // Access token can be fetched from cache or using a custom auth method.
            const token = await this.getAccessToken(this._auth)
            if (token) {
              init["headers"]["Authorization"] = `Bearer ${token.access_token}`
            }
          } else if (isAccessTokenAuth(this._auth)) {
            init["headers"]["Authorization"] = `${this._auth.token_type} ${this._auth.access_token}`
          }
        }
      } else if (typeof init.withAuth === "string") {
        this._debug(`Using custom authorization header.`)

        init["headers"]["Authorization"] = init.withAuth
      } else if (typeof init.withAuth === "function") {
        this._debug(`Using custom authorization callback.`)

        init["headers"]["Authorization"] = init.withAuth()
      } else if (isBasicAuth(init.withAuth)) {
        this._debug(`Using basic authorization header`)

        const basic = Buffer.from(
          `${init.withAuth.username}:${init.withAuth.password}`
        ).toString("base64")

        init["headers"]["Authorization"] = `Basic ${basic}`
      } else if (isClientIdSecretAuth(init.withAuth)) {
        // Fetch an access token and add it to the request.
        // Access token can be fetched from cache or using a custom auth method.
        const token = await this.getAccessToken(init.withAuth)
        if (token) {
          init["headers"]["Authorization"] = `Bearer ${token.access_token}`
        }
      } else if (isAccessTokenAuth(init.withAuth)) {
        init["headers"]["Authorization"] = `${init.withAuth.token_type} ${init.withAuth.access_token}`
      }
    }

    if (this.fetcher) {
      this._debug(`Using custom fetcher.`)

      return await this.fetcher(input, init)
    }

    this._debug(`Using default fetch (polyfilled by Next.js).`)

    return await fetch(input, init)
  }

  async getResource<T extends JsonApiResource>(
    type: string,
    uuid: string,
    options?: JsonApiWithLocaleOptions &
      JsonApiWithAuthOptions &
      JsonApiWithCacheOptions
  ): Promise<T> {
    options = {
      deserialize: true,
      withAuth: this.withAuth,
      withCache: false,
      params: {},
      ...options,
    }

    if (options.withCache) {
      const cached = (await this.cache?.get(options.cacheKey)) as string

      if (cached) {
        this._debug(`Returning cached resource ${type} with id ${uuid}`)

        const json = JSON.parse(cached)

        return options.deserialize ? this.deserialize(json) : json
      }
    }

    const apiPath = await this.getEntryForResourceType(
      type,
      options?.locale !== options?.defaultLocale ? options?.locale : undefined
    )

    const url = this.buildUrl(`${apiPath}/${uuid}`, options?.params)

    this._debug(`Fetching resource ${type} with id ${uuid}.`)
    this._debug(url.toString())

    const response = await this.fetch(url.toString(), {
      withAuth: options?.withAuth,
    })

    if (!response?.ok) {
      await this.handleJsonApiErrors(response)
    }

    const json = await response.json()

    if (options?.withCache) {
      await this.cache?.set(options.cacheKey, JSON.stringify(json))
    }

    return options?.deserialize ? this.deserialize(json) : json
  }

  async getResourceByPath<T extends JsonApiResource>(
    path: string,
    options?: {
      isVersionable?: boolean
    } & JsonApiWithLocaleOptions &
      JsonApiWithAuthOptions
  ): Promise<T> {
    options = {
      deserialize: true,
      isVersionable: false,
      withAuth: this.withAuth,
      params: {},
      ...options,
    }

    if (!path) {
      return null
    }

    if (
      options.locale &&
      options.defaultLocale &&
      path.indexOf(options.locale) !== 1
    ) {
      path = path === "/" ? path : path.replace(/^\/+/, "")
      path = this.getPathFromContext({
        params: { slug: [path] },
        locale: options.locale,
        defaultLocale: options.defaultLocale,
      })
    }

    // If a resourceVersion is provided, assume entity type is versionable.
    if (options.params.resourceVersion) {
      options.isVersionable = true
    }

    const { resourceVersion = "rel:latest-version", ...params } = options.params

    if (options.isVersionable) {
      params.resourceVersion = resourceVersion
    }

    const resourceParams = stringify(params)

    // We are intentionally not using translatePath here.
    // We want a single request using subrequests.
    const payload = [
      {
        requestId: "router",
        action: "view",
        uri: `/router/translate-path?path=${path}&_format=json`,
        headers: { Accept: "application/vnd.api+json" },
      },
      {
        requestId: "resolvedResource",
        action: "view",
        uri: `{{router.body@$.jsonapi.individual}}?${resourceParams.toString()}`,
        waitFor: ["router"],
      },
    ]

    // Localized subrequests.
    // I was hoping we would not need this but it seems like subrequests is not properly
    // setting the jsonapi locale from a translated path.
    // TODO: Confirm if we still need this after https://www.drupal.org/i/3111456.
    let subrequestsPath = "/subrequests"
    const url = this.buildUrl(subrequestsPath, {
      _format: "json",
    })

    const response = await this.fetch(url.toString(), {
      method: "POST",
      credentials: "include",
      redirect: "follow",
      body: JSON.stringify(payload),
      withAuth: options?.withAuth,
    })

    const json = await response.json()

    if (!json?.["resolvedResource#uri{0}"]?.body) {
      if (json?.router?.body) {
        const error = JSON.parse(json.router.body)
        if (error?.message) {
          this.throwError(new Error(error.message))
        }
      }

      return null
    }

    const data = JSON.parse(json["resolvedResource#uri{0}"]?.body)

    if (data.errors) {
      this.throwError(new Error(this.formatJsonApiErrors(data.errors)))
    }

    return options?.deserialize ? this.deserialize(data) : data
  }

  async getResourceCollection<T = JsonApiResource[]>(
    type: string,
    options?: {
      deserialize?: boolean
    } & JsonApiWithLocaleOptions &
      JsonApiWithAuthOptions
  ): Promise<T> {
    options = {
      withAuth: this.withAuth,
      deserialize: true,
      ...options,
    }

    const apiPath = await this.getEntryForResourceType(
      type,
      options?.locale !== options?.defaultLocale ? options?.locale : undefined
    )

    const url = this.buildUrl(apiPath, {
      ...options?.params,
    })

    this._debug(`Fetching resource collection of type ${type}`)
    this._debug(url.toString())

    const response = await this.fetch(url.toString(), {
      withAuth: options?.withAuth,
    })

    if (!response?.ok) {
      await this.handleJsonApiErrors(response)
    }

    const json = await response.json()

    return options?.deserialize ? this.deserialize(json) : json
  }

  getPathFromContext(
    context: GetStaticPropsContext,
    options?: {
      pathPrefix?: PathPrefix
    }
  ) {
    options = {
      pathPrefix: "/",
      ...options,
    }

    let slug = context.params?.slug

    let pathPrefix =
      options.pathPrefix?.charAt(0) === "/"
        ? options.pathPrefix
        : `/${options.pathPrefix}`

    // Handle locale.
    if (context.locale && context.locale !== context.defaultLocale) {
      pathPrefix = `/${context.locale}${pathPrefix}`
    }

    slug = Array.isArray(slug)
      ? slug.map((s) => encodeURIComponent(s)).join("/")
      : slug

    // Handle front page.
    if (!slug) {
      slug = this.frontPage
      pathPrefix = pathPrefix.replace(/\/$/, "")
    }

    slug =
      pathPrefix.slice(-1) !== "/" && slug?.charAt(0) !== "/" ? `/${slug}` : slug

    return `${pathPrefix}${slug}`
  }

  async getIndex(): Promise<JsonApiResponse> {
    const url = this.buildUrl(DEFAULT_API_PREFIX)

    try {
      const response = await this.fetch(url.toString(), {
        // As per https://www.drupal.org/node/2984034 /jsonapi is public.
        withAuth: false,
      })

      return await response.json()
    } catch (error: any) {
      this.throwError(
        new Error(
          `Failed to fetch JSON:API index at ${url.toString()} - ${
            error.message
          }`
        )
      )
    }
  }

  async getEntryForResourceType(
    type: string,
    locale?: Locale
  ): Promise<string> {
    if (this.useDefaultResourceTypeEntry) {
      const [id, bundle] = type.split("--")
      return (
        `${this.baseUrl}` + DEFAULT_API_PREFIX + `${id}/${bundle}`
      )
    }

    const index = await this.getIndex()

    const link = index.links?.[type] as { href: string }

    if (!link) {
      throw new Error(`Resource of type '${type}' not found.`)
    }

    const { href } = link

    // Fix for missing locale in JSON:API index.
    // This fix ensures the locale is included in the resouce link.
    if (locale) {
      const pattern = `^\\/${locale}\\/`
      const path = href.replace(this.baseUrl, "")

      if (!new RegExp(pattern, "i").test(path)) {
        return `${this.baseUrl}/${locale}${path}`
      }
    }

    return href
  }

  buildUrl(
    path: string,
    params?: string | Record<string, string> | URLSearchParams | JsonApiParams
  ): URL {
    const url = new URL(
      path.charAt(0) === "/" ? `${this.baseUrl}${path}` : path
    )

    if (typeof params === "object" && "getQueryObject" in params) {
      params = params.getQueryObject()
    }

    if (params) {
      // Used instead URLSearchParams for nested params.
      url.search = stringify(params)
    }

    return url
  }

  async getAccessToken(
    opts?: DrupalClientAuthClientIdSecret
  ): Promise<AccessToken> {
    if (this.accessToken && this.accessTokenScope === opts?.scope) {
      return this.accessToken
    }

    if (!opts?.clientId || !opts?.clientSecret) {
      if (typeof this._auth === "undefined") {
        throw new Error(
          "auth is not configured. See https://next-drupal.org/docs/client/auth"
        )
      }
    }

    if (
      !isClientIdSecretAuth(this._auth) ||
      (opts && !isClientIdSecretAuth(opts))
    ) {
      throw new Error(
        `'clientId' and 'clientSecret' required. See https://next-drupal.org/docs/client/auth`
      )
    }

    const clientId = opts?.clientId || this._auth.clientId
    const clientSecret = opts?.clientSecret || this._auth.clientSecret
    const url = this.buildUrl(opts?.url || this._auth.url || DEFAULT_AUTH_URL)

    if (
      this.accessTokenScope === opts?.scope &&
      this._token &&
      Date.now() < this.tokenExpiresOn
    ) {
      this._debug(`Using existing access token.`)
      return this._token
    }

    this._debug(`Fetching new access token.`)

    const basic = Buffer.from(`${clientId}:${clientSecret}`).toString("base64")

    let body = `grant_type=client_credentials`

    if (opts?.scope) {
      body = `${body}&scope=${opts.scope}`

      this._debug(`Using scope: ${opts.scope}`)
    }

    const response = await this.fetch(url.toString(), {
      method: "POST",
      headers: {
        Authorization: `Basic ${basic}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })

    if (!response?.ok) {
      await this.handleJsonApiErrors(response)
    }

    const result: AccessToken = await response.json()

    this._debug(result)

    this.token = result

    this.accessTokenScope = opts?.scope

    return result
  }

  deserialize(body: any, options?: any) {
    if (!body) return null

    return this.serializer?.deserialize(body, options)
  }

  private async getErrorsFromResponse(response: Response) {
    const type = response.headers.get("content-type")

    if (type === "application/json") {
      const error = await response.json()
      return error.message
    }

    // Construct error from response.
    // Check for type to ensure this is a JSON:API formatted error.
    // See https://jsonapi.org/format/#errors.
    if (type === "application/vnd.api+json") {
      const _error: JsonApiResponse = await response.json()

      if (_error?.errors?.length) {
        return _error.errors
      }
    }

    return response.statusText
  }

  private formatJsonApiErrors(errors: any) {
    const [error] = errors

    let message = `${error.status} ${error.title}`

    if (error.detail) {
      message += `\n${error.detail}`
    }

    return message
  }

  private _debug(message: any) {
    !!this.debug && this.logger?.debug(message)
  }

  // Error handling.
  // If throwErrors is enable, we show errors in the Next.js overlay.
  // Otherwise we log the errors even if debugging is turned off.
  // In production, errors are always logged never thrown.
  private throwError(error: Error) {
    if (!this.throwJsonApiErrors) {
      return this.logger?.error(error)
    }

    throw error
  }

  private async handleJsonApiErrors(response: Response) {
    if (!response?.ok) {
      const errors = await this.getErrorsFromResponse(response)
      throw new JsonApiErrors(errors, response.status)
    }
  }
}