import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { ApiParams } from "src/types";

export function getParams(resourceType: string, params: ApiParams) {
  const apiParams = new DrupalJsonApiParams();

  if (resourceType === "taxonomy_term--category") {
    apiParams.addInclude(["field_category_icon"]);
    apiParams.addSort("parent.weight,weight", "ASC"); // Required, because MySQL server returns collection in different sort order in comparision to MariaDB (see https://jira.telekom-mms.com/browse/BRLIVE-471)
  }

  if (
    resourceType === "node--video_others" ||
    resourceType === "node--plenary_meeting"
  ) {
    apiParams.addSort("created", "DESC");
  }

  if (resourceType === "node--plenary_meeting") {
    const includes = params.includes
      ? typeof params.includes === "string"
        ? [params.includes]
        : params.includes
      : undefined;

    if (includes) {
      apiParams.addInclude(includes);
    }

    if (params.meetingYear) {
      const min = (
        new Date(Date.UTC(params.meetingYear, 0, 1)).getTime() / 1000
      ).toString();
      const max = (
        new Date(Date.UTC(params.meetingYear + 1, 0, 1)).getTime() / 1000
      ).toString();
      apiParams.addFilter("created", min, ">=");
      apiParams.addFilter("created", max, "<");
    }

    if (params.cmsCategory) {
      apiParams.addFilter("field_cms_category", params.cmsCategory, "=");
    }
  }

  if (resourceType === "node--video_others") {
    apiParams.addInclude(["field_category", "field_videotype"]);

    if (params.categoryName) {
      apiParams.addFilter("field_category.name", params.categoryName, "IN");
    }
  }

  if (params.kmcId) {
    apiParams.addFilter("field_kmc_id", params.kmcId, "=");
  }

  if (params.limit) {
    apiParams.addPageLimit(params.limit);
  }

  return apiParams.getQueryObject();
}
