import { CSSProperties, ReactNode } from "react";

export function PrimaryButton({
  className,
  style,
  children,
  type = "button",
  disabled,
  ariaLabel,
  onClick,
}: {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  ariaLabel?: string;
  onClick?: () => void;
}) {
  return (
    <button
      type={type}
      disabled={!!disabled}
      aria-label={ariaLabel}
      className={`h-max flex items-center justify-between  bg-red-400  outline-none focus-visible:ring-1 focus-visible:ring-red-100 text-white px-2 py-1 ${
        className ? className : ""
      }`}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
