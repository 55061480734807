import { CategoryListAction } from "kaltura-typescript-client/api/types/CategoryListAction";
import { KalturaCategoryFilter, KalturaCategoryFilterArgs } from "kaltura-typescript-client/api/types/KalturaCategoryFilter";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { drupal } from "src/lib/drupal";
import { getParams } from "src/lib/get-params";
import { initKalturaSession, kaltura } from "src/lib/kaltura";
import { ApiParams, Category } from "src/types";

export async function getCategories(params: ApiParams = {}) {
  return await drupal.getResourceCollection<Category[]>(
    "taxonomy_term--category",
    {
      params: getParams("taxonomy_term--category", params),
    }
  );
}

export async function getKalturaCategories(filterBy?: KalturaCategoryFilterArgs) {
  const filter = new KalturaCategoryFilter(filterBy);
  const pager = new KalturaFilterPager({ pageSize: 500 });
  await initKalturaSession();
  const data = [];
  while (true) {
    const response = await kaltura.request(new CategoryListAction({ filter, pager }));
    if (!response) {
      break;
    }
    data.push(...response.objects);
    if (data.length >= response.totalCount) {
      break;
    }
  }
  return data;
}
