import { FormEvent, useCallback, useEffect, useState, useRef } from "react";
import {
  ActivePlenaryValues,
  Agenda as AgendaType,
  Speech,
  Video,
} from "src/types";
import { PrimaryButton } from "../button";
import { TimesIcon } from "../times-icon";
import { AgendaItemForm } from "./components/agenda-item-form";
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg";
import { Agenda } from "./components/agenda";
import {
  AgendaProps,
  AgendasContext,
  SpeechProps,
} from "./context/agendas.context";
import { OtherVideos } from "./components/other-videos";
import {
  ContainerHandle,
  ScrollContainer,
} from "./components/scroll-container";

export function Agendas({
  agendas,
  activeId,
  liveAgendaNumber,
  otherVideosBeforeAgenda,
  otherVideosAfterAgenda,
  introLive,
  disableActions,
  videoActions,
  onAgendaClick,
  onSpeechClick,
  onVideoClick,
}: {
  agendas: AgendaType[];
  activeId?: string | undefined;
  liveAgendaNumber?: string | undefined;
  otherVideosBeforeAgenda: Video[];
  otherVideosAfterAgenda: Video[];
  introLive?: boolean;
  disableActions?: boolean;
  videoActions?: JSX.Element;
  onAgendaClick: (agenda: AgendaType) => void;
  onSpeechClick: (speech: Speech, agenda: AgendaType) => void;
  onVideoClick: (video: Video) => void;
}) {
  const [nonDuplicateAgendas, setNonDuplicateAgendas] = useState<AgendaType[]>(
    []
  );
  const [activeAgenda, setActiveAgenda] = useState<AgendaType>();
  const [activeAssociatedAgenda, setActiveAssociatedAgenda] =
    useState<AgendaType>();
  const [liveAgenda, setLiveAgenda] = useState<AgendaType>();
  const [liveAssociatedAgenda, setLiveAssociatedAgenda] =
    useState<AgendaType>();
  const [recalledAgenda, setRecalledAgenda] = useState<AgendaType>();
  const [activeSpeech, setActiveSpeech] = useState<Speech>();
  const [activeVideo, setActiveVideo] = useState<Video>();
  const [activeAgendaItem, setActiveAgendaItem] = useState("");
  const [viewingLive, setViewingLive] = useState(true);
  const [scrollIntoViewID, setScrollIntoViewID] = useState<string>();
  const [activeAgendaRef, setActiveAgendaRef] = useState<HTMLDivElement>();
  const [liveAgendaRef, setLiveAgendaRef] = useState<HTMLDivElement>();
  const scrollContainerRef = useRef<ContainerHandle>(null);

  useEffect(() => {
    const associatedAgendas: string[] = [];
    const filteredAgendas = agendas.reduce<AgendaType[]>((prev, curr) => {
      if (!associatedAgendas.includes(curr.id)) {
        prev.push(curr);
      }
      curr.field_associated_with.forEach((ag) => associatedAgendas.push(ag.id));

      return prev;
    }, []);
    const recalledAgenda = agendas.find((agenda) => agenda.field_renewed_call);

    setNonDuplicateAgendas(filteredAgendas);
    setRecalledAgenda(recalledAgenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendas]);

  useEffect(() => {
    setLiveAssociatedAgenda(undefined);

    let liveAgenda = agendas.find(
      (ag) => ag.field_item_number === liveAgendaNumber
    );
    setLiveAgenda(liveAgenda);

    if (!liveAgenda) {
      for (let agenda of agendas) {
        const liveAssociatedAgenda = agenda.field_associated_with.find(
          (ag) => ag.field_item_number === liveAgendaNumber
        );
        if (liveAssociatedAgenda) {
          liveAgenda = agenda;
          setLiveAgenda(agenda);
          setLiveAssociatedAgenda(liveAssociatedAgenda);
          break;
        }
      }
    }
    if (viewingLive) {
      setActiveAgenda(liveAgenda);
      setActiveAssociatedAgenda(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendas, liveAgendaNumber]);

  const setActiveValues = useCallback(
    ({
      agenda,
      associatedAgenda,
      speech,
      video,
    }: Partial<ActivePlenaryValues>) => {
      setActiveAgenda(agenda);
      setActiveAssociatedAgenda(associatedAgenda);
      setActiveSpeech(speech);
      setActiveVideo(video);
      setViewingLive(agenda?.id === liveAgenda?.id);
    },
    [liveAgenda?.id]
  );

  useEffect(() => {
    if (activeId === undefined) {
      return;
    }
    let values = findActiveValues(nonDuplicateAgendas, [...otherVideosBeforeAgenda, ...otherVideosAfterAgenda], activeId);
    if (
      recalledAgenda &&
      !values.agenda &&
      !values.associatedAgenda &&
      !values.video
    ) {
      values = findActiveValues([recalledAgenda], [], activeId);
    }
    setActiveValues(values);
    if (values.speech) {
      onSpeechClick(values.speech, values.agenda!);
    } else if (values.video) {
      onVideoClick(values.video);
    } else if (values.agenda || values.associatedAgenda) {
      onAgendaClick(values.associatedAgenda || values.agenda!);
    }
    setScrollIntoViewID(
      values.associatedAgenda?.id ?? values.agenda?.id ?? values.video?.id
    );
  }, [
    nonDuplicateAgendas,
    otherVideosBeforeAgenda,
    otherVideosAfterAgenda,
    recalledAgenda,
    activeId,
    setActiveValues
  ]);

  useEffect(() => {
    setActiveAgendaItem(
      activeAssociatedAgenda?.field_item_number ||
        activeAgenda?.field_item_number ||
        ""
    );
  }, [activeAgenda, activeAssociatedAgenda]);

  const handleRef = (
    live: boolean,
    ref: HTMLDivElement,
    resetActive: boolean
  ) => {
    if (live) {
      setLiveAgendaRef(ref);
      if (resetActive) {
        setActiveAgendaRef(undefined);
      }
      if (viewingLive) {
        scrollEltIntoView(ref);
      }
    } else {
      setActiveAgendaRef(ref);
    }
  };

  const handleAgendaClick = ({
    agenda,
    associatedAgenda,
    agendaRef,
    scrollIntoView,
  }: AgendaProps) => {
    setActiveValues({ agenda, associatedAgenda });
    onAgendaClick(associatedAgenda || agenda);

    if (agendaRef) {
      scrollEltIntoView(agendaRef);
    }

    if (scrollIntoView) {
      setScrollIntoViewID(associatedAgenda?.id || agenda.id);
    }
  };

  const handleSpeechClick = ({
    agenda,
    associatedAgenda,
    agendaRef,
    speech,
  }: SpeechProps) => {
    setActiveValues({ agenda, associatedAgenda, speech });
    onSpeechClick(speech, agenda);

    if (agendaRef) {
      scrollEltIntoView(agendaRef);
    }
  };

  const handleVideoClick = (video: Video) => {
    setActiveValues({ video });
    setActiveAgendaRef(undefined);
    onVideoClick(video);
  };

  const handleAgendaItemSubmit = (
    evt: FormEvent<HTMLFormElement>,
    selectAgenda?: boolean
  ) => {
    evt.preventDefault();

    let agenda = agendas.find((a) => a.field_item_number === activeAgendaItem);

    if (!agenda) {
      for (let childAgenda of agendas) {
        agenda = childAgenda.field_associated_with.find(
          (a) => a.field_item_number === activeAgendaItem
        );
        if (agenda) {
          break;
        }
      }
    }

    if (agenda && selectAgenda) {
      onAgendaClick(agenda);
    }

    setScrollIntoViewID(agenda?.id);
  };

  const scrollEltIntoView = (elt: HTMLElement | null | undefined) => {
    scrollContainerRef.current?.scrollToElement(elt);
  };

  return agendas.length ? (
    <AgendasContext.Provider
      value={{
        agendas: nonDuplicateAgendas,
        activeAgenda,
        activeAssociatedAgenda,
        liveAgenda,
        recalledAgenda,
        liveAssociatedAgenda,
        activeSpeech,
        viewingLive,
        disabled: !!disableActions,
        scrollIntoViewID,
        activeAgendaRef,
        liveAgendaRef,
        onAgendaClick: handleAgendaClick,
        onSpeechClick: handleSpeechClick,
        onRef: handleRef,
        onScrollFinish: () => setScrollIntoViewID(undefined),
      }}
    >
      <div className="relative flex flex-col flex-auto overflow-hidden md:block md:flex-none md:overflow-visible">
        <div className="flex md:hidden justify-between items-center text-white bg-red-400 px-4 py-0.5">
          <AgendaItemForm
            selectedAgendaItem={activeAgendaItem}
            activeAgendaItem={
              activeAssociatedAgenda?.field_item_number ||
              activeAgenda?.field_item_number
            }
            setSelectedAgendaItem={setActiveAgendaItem}
            handleSubmit={handleAgendaItemSubmit}
          />
          {videoActions}
          {liveAgendaRef ? (
            <button
              className="py-2"
              disabled={!liveAgendaRef}
              onClick={() => scrollEltIntoView(liveAgendaRef)}
            >
              zum LIVE TOP
            </button>
          ) : undefined}
        </div>
        <ScrollContainer
          activeElement={activeAgendaRef}
          liveElement={liveAgendaRef}
          activeAgendaNumber={
            activeAssociatedAgenda?.field_item_number ||
            activeAgenda?.field_item_number
          }
          ref={scrollContainerRef}
        >
          {otherVideosBeforeAgenda.length ? (
            <li>
              <OtherVideos
                videos={otherVideosBeforeAgenda}
                activeVideo={activeVideo}
                openerLive={!!introLive}
                borderClassname={"border-b-4"}
                onClick={handleVideoClick}
              />
            </li>
          ) : undefined}
          {nonDuplicateAgendas.map((agenda, i) => (
            <li key={`agenda_item_${agenda.id}_${i}`}>
              <Agenda
                className={`${i % 2 ? "bg-gray-400/50" : "bg-black/0"} `}
                agenda={agenda}
              />
            </li>
          ))}
          {otherVideosAfterAgenda.length ? (
            <li>
              <OtherVideos
                videos={otherVideosAfterAgenda}
                activeVideo={activeVideo}
                openerLive={!!introLive}
                borderClassname={"border-t-4"}
                onClick={handleVideoClick}
              />
            </li>
          ) : undefined}
        </ScrollContainer>

        <form className="hidden md:flex mt-2" onSubmit={handleAgendaItemSubmit}>
          <div className="bg-white px-2 py-1">
            <span className="text-2xs">Gehe zu TOP</span>
            <input
              placeholder="Nr."
              className="max-w-[80px] text-2xs ml-2 mr-0.5 p-0.5 focus:outline focus:outline-red-400 focus:outline-2"
              onChange={(evt) => setActiveAgendaItem(evt.target.value)}
            />
            <button
              type="reset"
              aria-label="Reset"
              className={`p-0.5 focus:outline focus:outline-red-400 focus:outline-2 ${
                activeAgendaItem ? "visible" : "invisible"
              }`}
              onClick={() => setActiveAgendaItem("")}
            >
              <TimesIcon />
            </button>
          </div>
          <PrimaryButton type="submit" ariaLabel="Senden" className="h-auto">
            <Arrow />
          </PrimaryButton>
        </form>
      </div>
    </AgendasContext.Provider>
  ) : (
    <p className="px-4">Es gibt keine Tagesordnungpunkten.</p>
  );
}

function findActiveValues(
  agendas: AgendaType[],
  videos: Video[],
  activeId: string,
  depth = 0
) {
  let agenda: AgendaType | undefined;
  let associatedAgenda: AgendaType | undefined;
  let speech: Speech | undefined;
  let video: Video | undefined;
  if (!depth) {
    for (const videoItem of videos) {
      if (videoItem.id === activeId) {
        video = videoItem;
        return { agenda, associatedAgenda, speech, video };
      }
    }
  }
  for (const agendaItem of agendas) {
    if (agendaItem.id === activeId) {
      agenda = agendaItem;
      break;
    }
    speech = agendaItem.field_speeches.find((s) => s.id === activeId);
    if (speech) {
      agenda = agendaItem;
      break;
    }
    if (!depth) {
      const data = findActiveValues(
        agendaItem.field_associated_with,
        videos,
        activeId,
        depth + 1
      );
      associatedAgenda = data.agenda;
      speech = data.speech;
    }
  }
  return { agenda, associatedAgenda, speech, video };
}
