import {
  createContext,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { DropdownClick } from "src/types";
import { ReactComponent as Caret } from "../assets/caret-right.svg";

export const DropdownContext = createContext<{
  selected: any;
  selectedId: string;
  onClick: (value: any) => void;
}>({
  selected: undefined,
  selectedId: "",
  onClick: () => {},
});

export function Dropdown({
  label,
  children,
  className,
}: {
  label: string;
  children: ReactNode;
  className?: string;
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [childButtons, setChildButtons] = useState<HTMLButtonElement[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const id = Math.random().toString(32).slice(2);

  useEffect(() => {
    const closeDialog = () => setOpen(false);
    window.addEventListener("click", closeDialog);
    window.addEventListener("dropdownClick", handleDropdownClick);

    return () => {
      window.removeEventListener("click", closeDialog);
      window.removeEventListener("dropdownClick", handleDropdownClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      const children = containerRef.current?.querySelectorAll(
        "button"
      ) as NodeListOf<HTMLButtonElement>;

      const childrenArray = Array.from(children);
      setChildButtons(childrenArray);

      let selected = false;
      for (let i = 0; i <= childrenArray.length; i++) {
        if (childrenArray[i]?.getAttribute("aria-selected") === "true") {
          childrenArray[i].focus();
          setActiveIndex(i);
          selected = true;
          break;
        }
      }

      if (!selected) {
        childrenArray[0]?.focus();
      }
    } else {
      setChildButtons([]);
      setActiveIndex(0);
    }
  }, [open]);

  const handleContainerClick = (evt: MouseEvent) => {
    evt.stopPropagation();
    const event = new Event("dropdownClick") as DropdownClick;
    event.id = id;
    window.dispatchEvent(event);
  };

  const handleDropdownClick = (evt: DropdownClick) => {
    if (evt.id !== id) {
      setOpen(false);
    }
  };

  const handleOptionClick = (val: any) => {
    setSelected(val);
  };

  const handleContainerKeydown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === "Tab") {
      setOpen(false);
      buttonRef.current?.focus();
      return;
    }
    if (!["Enter", " "].includes(evt.key)) {
      evt.preventDefault();
    }
    const lastIndex = childButtons.length - 1;
    if (["PageUp", "PageDown"].includes(evt.key)) {
      focusNext(evt.key === "PageUp" ? 0 : lastIndex);
      return;
    }
    if (!["ArrowDown", "ArrowUp"].includes(evt.key)) {
      return;
    }
    const next = evt.key === "ArrowDown" ? activeIndex + 1 : activeIndex - 1;
    if (next <= lastIndex && next >= 0) {
      focusNext(next);
      return;
    }
    focusNext(evt.key === "ArrowDown" ? 0 : lastIndex);
  };

  const focusNext = (next: number) => {
    childButtons[next].focus();
    setActiveIndex(next);
  };

  return (
    <div className={`relative ${className ? className : ""}`}>
      <button
        className={`relative w-full flex items-center justify-center text-center text-lg bg-red-400 text-white 
        hover:font-semibold border-2 border-red-400 focus:outline-none hover:border-red-100 focus:border-red-100 p-4 cursor-pointer`}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls={id}
        aria-owns={id}
        aria-expanded={open}
        aria-activedescendant={`selected-${id}`}
        onClick={(evt) => {
          handleContainerClick(evt);
          setOpen(!open);
        }}
        ref={buttonRef}
      >
        {selected || label}
        <Caret
          className={`stroke-white  absolute right-3 w-2 transition-transform ${
            open ? "-rotate-90" : "rotate-90"
          }`}
        />
      </button>
      {open && (
        <div
          id={id}
          className="absolute w-full flex flex-col items-center gap-1 left-0 top-full p-2 bg-white border border-t-0 border-gray-300 shadow-lg rounded-b-md z-[1000]"
          ref={containerRef}
          onClick={(evt) => handleContainerClick(evt)}
          onKeyDown={handleContainerKeydown}
        >
          <DropdownContext.Provider
            value={{
              selected,
              selectedId: `selected-${id}`,
              onClick: handleOptionClick,
            }}
          >
            {children}
          </DropdownContext.Provider>
        </div>
      )}
    </div>
  );
}
