import React, { ForwardedRef } from "react";
import { ReactComponent as DownloadIcon } from "../assets/download.svg";

interface DownloadButtonProps {
  className?: string;
  overlayed?: boolean;
  handleClick: () => void;
}

export const DownloadButton = React.forwardRef(
  ({ className = "", overlayed, handleClick }: DownloadButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        className={`flex justify-center items-center w-9 h-9 border-none ${
          overlayed ? "bg-black bg-opacity-50 hover:bg-opacity-75 focus:bg-opacity-75" : "bg-transparent"
        } ${className}`}
        aria-label="Downloadbereich"
        onClick={() => handleClick()}
      >
        <DownloadIcon className="stroke-white" />
      </button>
    );
  }
);
