import { ReactNode } from "react";
import { ReactComponent as Caret } from "../assets/caret-right.svg";

export function OutsideLink({
  children,
  href,
  className,
}: {
  children: ReactNode;
  href: string;
  className?: string;
}) {
  return (
    <a
      className={`flex items-center justify-between w-[250px] font-bold text-red-400 pb-0.5 hover:underline focus:underline focus:outline-none border-b border-dotted border-gray-900 ${
        className ? className : ""
      }`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      <Caret className="stroke-red-400 w-[8px]" />
    </a>
  );
}
