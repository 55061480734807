import { ReactNode, Children, forwardRef, ForwardedRef } from "react";
import { Category } from "src/types";
import { VideoGallery } from "./video-gallery";
import { CategoryLink } from "./category-link";

export const Swimlane = forwardRef(
  (
    {
      className,
      category,
      children,
      hideLink,
    }: {
      className?: string;
      category: Category;
      children: ReactNode;
      hideLink?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {

    const childrenArray = Children.toArray(children);

    return (
      <div
        className={`focus:outline-none ${className ? className : ""}`}
        tabIndex={-1}
        ref={ref}
      >
        <div className="flex flex-wrap items-baseline justify-between py-4">
          <h2 className="text-2xl font-serif text-gray-900 mr-3">
            {category.name}
          </h2>

          {!hideLink && childrenArray.length > 4 && (
            <CategoryLink to={category.path.alias} />
          )}
        </div>
        <VideoGallery>{childrenArray.slice(0, 4)}</VideoGallery>
      </div>
    );
  }
);
