import { useEffect, useRef, useState } from "react";

export function Countdown({
  date,
  long,
  className = "",
  reachTime = 0,
  reached,
}: {
  date: Date;
  long?: boolean;
  className?: string;
  reachTime?: number;
  reached?: () => void;
}) {
  const [countdown, setCountdown] = useState<string>();
  const reachedCalled = useRef(false);

  useEffect(() => {
    generateCountdown();

    const interval = setInterval(() => {
      generateCountdown();
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const generateCountdown = () => {
    const now = Date.now();
    const distance = date.getTime() - now;

    if (distance <= reachTime && !reachedCalled.current) {
      reachedCalled.current = true;
      reached?.();
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const hoursMinutesSeconds = `${parseValue(hours)}:${parseValue(
      minutes
    )}:${parseValue(seconds)}`;

    if (days <= 0) {
      return setCountdown(`Live in ${hoursMinutesSeconds} Stunden`);
    }

    setCountdown(
      `Live&nbsp;in&nbsp;${
        days === 1 ? "1&nbsp;Tag" : `${days}&nbsp;Tagen`
      }&nbsp;und ${hoursMinutesSeconds}&nbsp;Stunden`
    );
  };

  const parseValue = (val: number) => {
    return `${val > 0 ? `${val >= 10 ? val : "0" + val}` : "00"}`;
  };

  return (
    <span
      className={`${
        long ? "max-w-none" : "max-w-[20ch]"
      } md:max-w-none ${className}`}
      dangerouslySetInnerHTML={{ __html: countdown as string }}
    ></span>
  );
}
