export function CaretRight({ className = "" }: { className?: string }) {
  return (
    <svg
      className={className}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="6px"
      height="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 -90 -115 )">
        <path
          d="M 0 0  L 0 2.168  L 3.833333333333333 6  L 0 9.831999999999999  L 0 12  L 6 6  L 0 0  Z "
          fillRule="nonzero"
          fill="currentColor"
          stroke="none"
          transform="matrix(1 0 0 1 90 115 )"
        />
      </g>
    </svg>
  );
}
