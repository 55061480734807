import { ReactNode, useEffect, useRef, useState } from "react";
import { Popup } from "./popup";
import { ReactComponent as ClosePopupIcon } from "../assets/close-popup.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as WhatsappIcon } from "../assets/whatsapp.svg";
import { ReactComponent as MailIcon } from "../assets/mail.svg";
import { ReactComponent as LinkIcon } from "../assets/link.svg";
import { ReactComponent as CodeIcon } from "../assets/code.svg";
import { ActiveEntry, OptionalRefObject } from "src/types";

const REACT_APP_KALTURA_BASE_URL = process.env.REACT_APP_KALTURA_BASE_URL;
const REACT_APP_KALTURA_PARTNER_ID = process.env.REACT_APP_KALTURA_PARTNER_ID;
const REACT_APP_KALTURA_PLAYER_UICONF_ID = process.env.REACT_APP_KALTURA_PLAYER_UICONF_ID;

interface PlenarySharedProps {
  entry?: ActiveEntry | null;
  showEmbed?: boolean;
  anchors?: Array<OptionalRefObject> | OptionalRefObject;
  handleCancel?: () => void;
  handleClickOutside?: () => void;
  className?: string;
}

export function PlenaryShare({
  entry,
  showEmbed = true,
  anchors,
  handleCancel,
  handleClickOutside,
  className = "",
}: PlenarySharedProps) {
  const [id, setId] = useState("");
  const [mailto, setMailto] = useState("");

  useEffect(() => {
    const id = entry?.field_legacy_id ?? entry?.field_kmc_id ?? "";
    const mailtoBody = encodeURI(`https://www.bundesrat.de/DE/service/mediathek/mediathek-node.html?cms_id=${id}`);
    setId(id);
    setMailto(`mailto:?body=${mailtoBody}`);
  }, [entry]);

  return (
    <Popup
      className={`w-[375px] bg-white border border-gray-100 ${className}`}
      anchors={anchors}
      handleClickOutside={handleClickOutside}
    >
      <div className={`flex flex-col p-4`}>
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold">Teilen</span>
          {handleCancel && (
            <button
              className="flex justify-center items-center border-none"
              aria-label="Schließen"
              onClick={() => handleCancel()}
            >
              <ClosePopupIcon />
            </button>
          )}
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          <SocialLink
            ariaLabel="Facebook"
            link={`https://www.facebook.com/sharer/sharer.php?u=https://www.bundesrat.de/DE/service/mediathek/mediathek-node.html?cms_id=${id}`}
          >
            <FacebookIcon />
          </SocialLink>
          <SocialLink
            ariaLabel="Twitter"
            link={`https://twitter.com/intent/tweet?url=https://www.bundesrat.de/DE/service/mediathek/mediathek-node.html?cms_id=${id}`}
          >
            <TwitterIcon />
          </SocialLink>
          <SocialLink
            ariaLabel="Whatsapp"
            link={`https://api.whatsapp.com/send/?text=https://www.bundesrat.de/DE/service/mediathek/mediathek-node.html?cms_rubrik=${id}&type=custom_url&app_absent=0`}
          >
            <WhatsappIcon />
          </SocialLink>
          <SocialLink className="p-[4px] bg-gray-500" ariaLabel="Mail" link={mailto}>
            <MailIcon />
          </SocialLink>
        </div>
        <InputField value={`https://www.bundesrat.de/DE/service/mediathek/mediathek-node.html?cms_id=${id}`}>
          <LinkIcon />
        </InputField>
        {showEmbed && (
          <InputField
            className="mt-2"
            value={`<iframe type="text/javascript" src="${REACT_APP_KALTURA_BASE_URL}/p/${REACT_APP_KALTURA_PARTNER_ID}/embedPlaykitJs/uiconf_id/${REACT_APP_KALTURA_PLAYER_UICONF_ID}?iframeembed=true&entry_id=${entry?.field_kmc_id}" style="width: 560px; height: 395px" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" frameborder="0"></iframe>`}
          >
            <CodeIcon />
          </InputField>
        )}
      </div>
    </Popup>
  );
}

interface SocialLinkProps {
  className?: string;
  children?: ReactNode;
  ariaLabel?: string;
  link: string;
}

function SocialLink({ className = "", children, ariaLabel, link }: SocialLinkProps) {
  return (
    <a
      className={`rounded overflow-hidden ${className}`}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
}

interface InputFieldProps {
  className?: string;
  children?: ReactNode;
  value: string;
}

function InputField({ className = "", children, value }: InputFieldProps) {
  const [showCopied, setShowCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    setShowCopied(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowCopied(false);
      timeoutRef.current = null;
    }, 2500);
  };

  return (
    <div className={`flex ${className}`}>
      {children && <div className="shrink-0 mt-[6px] ml-1 mr-3">{children}</div>}
      <div className="flex flex-col flex-1 min-w-0">
        <input
          className="flex-1 min-w-0 px-1 border-2 border-gray-300"
          type="text"
          value={value}
          readOnly
          onClick={handleClick}
        />
        {showCopied && <div className="mt-1 text-xs text-gray-500">In die Zwischenablage kopiert</div>}
      </div>
    </div>
  );
}
